import React, { useEffect, useRef, useState } from "react";
import Timeline from "react-visjs-timeline";
import "vis/dist/vis.min.css";
import {
  calculateTotalTime,
  convertStartAndEndTimes,
  convertTimestampToFormattedDate,
} from "../../utils/common";
import { useSelector } from "react-redux";

const ServiceWiseTimeline = () => {
  const timelineRef = useRef();
  const lookUp = useSelector((state) => state.global.lookUpData);
  const selectedDay = useSelector((state) => state.global.selectedDay);
  const [groupData, setGroupData] = useState([]);
  const [itemsData, setItemsData] = useState([]);
  const [options, setOptions] = useState({
    stack: false,
    height: "35vh",
    zoomMin: 10000,
    orientation: { axis: "top", item: "top" },
    groupOrder: "content",
    showTooltips: true,
    preferZoom: true,
    verticalScroll: true,
    groupHeightMode: "fitItems",
  });

  const getCrewMemberName = (crewMemberId) => {
    const crewData = lookUp?.crewMembers;
    if (crewData[crewMemberId]) {
      return crewData[crewMemberId].name;
    } else {
      return "Crew member not found";
    }
  };

  const getServiceName = (serviceId) => {
    const servicesData = lookUp?.services;
    if (servicesData[serviceId]) {
      return servicesData[serviceId].serviceName;
    } else {
      return "Service not found";
    }
  };
  const getEquipmentName = (crewEquipmentId) => {
    const equipments = lookUp?.equipments;
    if (equipments[crewEquipmentId]) {
      return equipments[crewEquipmentId].type;
    } else {
      return "Equipment not found";
    }
  };

  const transformDataForTimeline = (lookup) => {
    const crewMemberDayWise = lookup.crewMemberDayWise;
    const timelineData = [];

    for (const crewId in crewMemberDayWise) {
      const crewData = crewMemberDayWise[crewId];
      let DayPolygons;
      if (crewId == 0) {
        DayPolygons = crewData.day.filter((dayData) => dayData.day == 0);
      } else if (selectedDay == "All") {
        DayPolygons = crewData.day;
      } else {
        DayPolygons = crewData.day.filter(
          (dayData) => dayData.day == selectedDay
        );
      }
      DayPolygons.forEach((dayData) => {
        const polygons = dayData.polygon;

        polygons.forEach((polygonId) => {
          const polygon = lookup.polygons[polygonId];
          const crewMemberName = getCrewMemberName(polygon.crewMemberId);
          const serviceName = getServiceName(polygon?.serviceId);
          const equipmentName = getEquipmentName(polygon.crewEquipmentId);

          const task = {
            id: polygonId,
            content: `${polygonId}`,
            start: convertTimestampToFormattedDate(polygon.arrivalTime),
            end: convertTimestampToFormattedDate(polygon.departureTime),
            group: `${crewMemberName} ${serviceName} ${polygon?.serviceId}`,
            title: `<div>
              <div class="individualContainer">
              <div>Crew Member : ${crewMemberName}</div>
              <div>Service Name : ${serviceName.replace("_", " ")}</div>
              <div>Equipment Name : ${equipmentName.replace("_", " ")}</div>
              <div>Start Time : ${convertTimestampToFormattedDate(
                polygon.arrivalTime
              )}</div>
              <div>End Time : ${convertTimestampToFormattedDate(
                polygon.departureTime
              )}</div>
              </div>
              </div>`,
            className: `${serviceName.replace(" ", "_")}`, // You can define custom CSS classes here
          };

          timelineData.push(task);
        });
      });
    }

    return timelineData;
  };
  const transformDataForTimelineSummary = (lookup) => {
    const polygonsData = lookup.polygons;
    const serviceObjects = {};

    for (const polygonId in polygonsData) {
      const polygon = polygonsData[polygonId];
      const serviceName = getServiceName(polygon?.serviceId);

      // Check if the service object exists, if not, create one
      if (!serviceObjects[serviceName]) {
        serviceObjects[serviceName] = {
          arrivalTime: polygon.arrivalTime,
          departureTime: polygon.departureTime,
          polygonId: polygonId,
          serviceId: polygon.serviceId,
          crewMemberId: polygon.crewMemberId,
        };
      } else {
        // Update arrivalTime if the current polygon's arrivalTime is less
        if (polygon.arrivalTime < serviceObjects[serviceName].arrivalTime) {
          serviceObjects[serviceName].arrivalTime = polygon.arrivalTime;
        }

        // Update departureTime if the current polygon's departureTime is greater
        if (polygon.departureTime > serviceObjects[serviceName].departureTime) {
          serviceObjects[serviceName].departureTime = polygon.departureTime;
        }
      }
    }

    // Convert the serviceObjects into an array
    const resultArray = Object.entries(serviceObjects).map(
      ([serviceName, times]) => ({
        start: convertTimestampToFormattedDate(times.arrivalTime),
        end: convertTimestampToFormattedDate(times.departureTime),
        className: serviceName.replace(/\s/g, "_"),
        content: serviceName.replace("_", " "),
        id: `${times.serviceId} ${times.crewMemberId}`,
        group: times.serviceId,
        title: `<div>
              <div class="individualContainer">
              <div>Service Name : ${serviceName.replace("_", " ")}
              <div>Start Time : ${convertTimestampToFormattedDate(
                times.arrivalTime
              )}</div>
              <div>End Time : ${convertTimestampToFormattedDate(
                times.departureTime
              )}</div>
              <div>Total Time : ${calculateTotalTime(
                times.arrivalTime,
                times.departureTime
              )}</div>
              </div>
              </div>`,
      })
    );

    return resultArray;
  };

  const transformGroupDataForTimeline = (lookup) => {
    const crewMemberDayWise = lookup.crewMemberDayWise;
    const services = lookup.services;
    const groupData = [];

    for (const crewId in crewMemberDayWise) {
      const crewData = crewMemberDayWise[crewId];
      let DayPolygons;
      if (crewId == 0) {
        DayPolygons = crewData.day.filter((dayData) => dayData.day == 0);
      } else if (selectedDay == "All") {
        DayPolygons = crewData.day;
      } else {
        DayPolygons = crewData.day.filter(
          (dayData) => dayData.day == selectedDay
        );
      }
      DayPolygons.forEach((dayData) => {
        const polygons = dayData.polygon;

        polygons.forEach((polygonId) => {
          const polygon = lookup.polygons[polygonId];
          const crewMemberName = getCrewMemberName(polygon.crewMemberId);
          const uniqueId = `${crewMemberName} ${getServiceName(
            polygon?.serviceId
          )} ${polygon?.serviceId}`;

          const group = {
            id: uniqueId,
            content: `${crewMemberName}`,
            // className: 'timeline-event', // You can define custom CSS classes here
          };
          const groupExists = groupData.some(
            (existingGroup) => existingGroup.id === uniqueId
          );

          if (!groupExists) {
            groupData.push(group);
          }
        });
      });
    }

    for (const serviceId in services) {
      const nestedGroups = [];
      const group = {
        id: serviceId,
        content: `${getServiceName(serviceId).replace("_", " ")}`,
        showNested: false,
        // className: 'timeline-event', // You can define custom CSS classes here
      };
      groupData.forEach((item) => {
        if (item.id.includes(getServiceName(serviceId))) {
          nestedGroups.push(item.id);
        }
      });

      const containsName = groupData.some((item) =>
        item.id.includes(getServiceName(serviceId))
      );

      if (containsName) {
        group.nestedGroups = nestedGroups;
      }
      groupData.push(group);
    }

    return groupData;
  };

  const findMinMaxTimeFromLookup = (lookup) => {
    let minStartTime = null;
    let maxEndTime = null;
    const crewMemberDayWise = lookup.crewMemberDayWise;

    for (const crewId in crewMemberDayWise) {
      const crewData = crewMemberDayWise[crewId];
      let DayPolygons;
      if (crewId == 0) {
        DayPolygons = crewData.day.filter((dayData) => dayData.day == 0);
      } else if (selectedDay == "All") {
        DayPolygons = crewData.day;
      } else {
        DayPolygons = crewData.day.filter(
          (dayData) => dayData.day == selectedDay
        );
      }
      DayPolygons.forEach((dayData) => {
        const polygons = dayData.polygon;

        polygons.forEach((polygonId) => {
          const polygon = lookup.polygons[polygonId];
          const startDate = convertTimestampToFormattedDate(
            polygon.arrivalTime
          );
          const endDate = convertTimestampToFormattedDate(
            polygon.departureTime
          );
          if (!minStartTime || startDate < minStartTime) {
            minStartTime = startDate;
          }
          if (!maxEndTime || endDate > maxEndTime) {
            maxEndTime = endDate;
          }
        });
      });
    }

    return { minStartTime, maxEndTime };
  };

  useEffect(() => {
    if (lookUp) {
      let lookupData = { ...lookUp };
      const timelineData = transformDataForTimeline(lookupData);
      setItemsData(timelineData);
      const timelineDataSummary = transformDataForTimelineSummary(lookupData);
      setItemsData((prevData) => [...prevData, ...timelineDataSummary]);
      const groupData = transformGroupDataForTimeline(lookupData);
      setGroupData(groupData);
      const bounds = findMinMaxTimeFromLookup(lookupData);
      const chartStart = new Date(bounds.minStartTime);
      const chartEnd = new Date(bounds.maxEndTime);
      const adjustedTimes = convertStartAndEndTimes(chartStart, chartEnd);
      const clusterOpts = {
        start: adjustedTimes.start,
        end: adjustedTimes.end,
        min: adjustedTimes.start + 1,
        max: adjustedTimes.end + 1,
      };
      setOptions((prevOptions) => ({
        ...prevOptions,
        ...clusterOpts,
      }));
    }
  }, [lookUp, selectedDay]);

  return (
    <div>
      {groupData && (
        <Timeline
          ref={timelineRef}
          options={options}
          groups={groupData}
          items={itemsData}
        />
      )}
    </div>
  );
};

export default ServiceWiseTimeline;
