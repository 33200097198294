import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { DeckGlOverlay } from "../../../utils/deckgl-overlay";
import { IconLayer } from "@deck.gl/layers";
import { hexToRgb } from "../../../utils/common";
import { routeGeneration } from "../../../utils/newPlannerFunctions";
import turfAlong from "@turf/along"; // For placing arrows along the path
import turfLength from "@turf/length"; // For calculating path length
import { lineString } from "@turf/helpers";

function svgToDataURL(color, angle) {
  const rotate = `rotate(${82 - angle})`;
  const colorString = Array.isArray(color)
    ? `rgb(${color[0]}, ${color[1]}, ${color[2]})`
    : color;
  return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(
    `<svg
      fill="${colorString}"
      height="50px"
      width="50px"
      viewBox="0 0 20 20"
      transform="${rotate}"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g data-name="Layer 2">
        <g data-name="navigation">
          <rect width="20" height="20" opacity="0" />
          <path d="M20 20a.94.94 0 0 1-.55-.17l-6.9-4.56a1 1 0 0 0-1.1 0l-6.9 4.56a1 1 0 0 1-1.44-1.28l8-16a1 1 0 0 1 1.78 0l8 16a1 1 0 0 1-.23 1.2A1 1 0 0 1 20 20z" />
        </g>
      </g>
    </svg>`,
  )}`;
}

function getDeckGlLayers(data, zoom, showArrowMarkers) {
  if (!data) return [];
  return [
    new IconLayer({
      id: "arrowLayer",
      data: data,
      getColor: (d) => d.color,
      getIcon: (d) => ({
        url: svgToDataURL(d.color, d.angle),
        width: 30, // Match this with the SVG width
        height: 30, // Match this with the SVG height
      }),
      getPosition: (d) => d.coordinates,
      pickable: true,
      // getAngle: (d) => d.angle,
      getSize: 20,
      visible: showArrowMarkers ? true : false,
      //   updateTriggers: {
      //     getSize: zoom,
      //     visible: zoom,
      //   },
    }),
  ];
}

const PlannerCrewMemberWiseDirectionArrows = ({
  crewMemberDayWise,
  viewport,
  lookUpData,
  selectedServiceIds,
}) => {
  const showArrowMarkers = useSelector(
    (state) => state.planningDashboard.showArrowMarkers,
  );

  const calculateArrowPositions = (connectingLinesData) => {
    const arrowPositions = [];
    const minDistanceThreshold = 0.005; // Minimum distance in kilometers between arrows
  
    connectingLinesData.forEach((line) => {
      const { source, target, color } = line;
      if (!source || !target) return;
  
      const path = lineString([source, target]);
      const pathLength = turfLength(path, { units: "kilometers" });
  
      if (pathLength > minDistanceThreshold) {
        for (let distance = minDistanceThreshold; distance < pathLength; distance += minDistanceThreshold) {
          const point = turfAlong(path, distance, { units: "kilometers" });
          const coordinates = point.geometry.coordinates;
  
          const dx = target[0] - source[0];
          const dy = target[1] - source[1];
          const angle = (Math.atan2(dy, dx) * 180) / Math.PI;
  
          arrowPositions.push({
            position: coordinates,
            angle,
            color,
          });
        }
      }
    });
  
    return arrowPositions;
  };

  const [isVisible, setIsVisible] = useState(true);

  const [arrowPositionsState, setArrowPositionsState] = useState([]);

  useEffect(() => {
    if (crewMemberDayWise && lookUpData) {
      const routePlan = [];
      const newArrowPositions = [];
      Object.entries(crewMemberDayWise).forEach(([crewMemberId, crewData]) => {
        const filteredPolygonIds = crewData.polygons.filter((polygonId) => {
          const polygon = lookUpData.polygons[polygonId];
          return (
            polygon && selectedServiceIds.includes(polygon.serviceId.toString())
          );
        });
        routePlan.push(
          ...routeGeneration(crewMemberId, filteredPolygonIds, lookUpData),
        );
      });

      if (routePlan) {
        let arrowPositions = calculateArrowPositions(routePlan);
        if (arrowPositions) {
          newArrowPositions.push(
            ...arrowPositions.map(({ position, angle, color }) => ({
              coordinates: [position[0], position[1]],
              angle,
              color: color,
            })),
          );
        }
      }
      setArrowPositionsState(newArrowPositions);
    }
  }, [crewMemberDayWise, lookUpData, selectedServiceIds]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsVisible(!document.hidden);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <>
      <DeckGlOverlay
        layers={getDeckGlLayers(arrowPositionsState, viewport?.detail?.zoom, showArrowMarkers)}
        isVisible={isVisible}
      />
    </>
  );
};

export default PlannerCrewMemberWiseDirectionArrows;
