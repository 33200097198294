import React, { useEffect, useState } from "react";
import { DeckGlOverlay } from "../../../utils/deckgl-overlay";
import { PathLayer } from "@deck.gl/layers";
import { PathStyleExtension } from "@deck.gl/extensions";
import useViewportWidth from "../../../Hooks/useViewportWidth";
import { routeGeneration } from "../../../utils/newPlannerFunctions";
function getDeckGlLayers(lineData, showConnectingLines) {
  if (!lineData || !showConnectingLines) return [];

  return [
    new PathLayer({
      id: "dashed-path-layer-planned",
      data: lineData,
      getPath: (d) => [d.source, d.target], // Adjust according to your data structure
      getColor: (d) => d.color,
      getWidth: 0.2,
      getDashArray: [5, 5],
      extensions: [new PathStyleExtension({ dash: true })],
      dashJustified: true,
      dashGapPickable: true,
      widthMinPixels: 0.2,
      // widthMaxPixels: 0.05,
      visible: showConnectingLines ? true : false,
      pickable: false,
    }),
  ];
}

const PlanningTransitionLines = ({
  crewMemberDayWise,
  lookUpData,
  showConnectingLines,
  selectedServiceIds
}) => {
  const [lineData, setLineData] = useState([]);
  const [isVisible, setIsVisible] = useState(true);
  const width = useViewportWidth();

  // this visible functionality is added due to webgl context lost issue
  useEffect(() => {
    const handleVisibilityChange = () => {
    setIsVisible(!document.hidden);
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
    document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (crewMemberDayWise && lookUpData) {
      const routePlan = [];
      
      Object.entries(crewMemberDayWise).forEach(([crewMemberId, crewData]) => {
        const filteredPolygonIds = crewData.polygons.filter((polygonId) => {
          const polygon = lookUpData.polygons[polygonId];
          return (
            polygon &&
            selectedServiceIds.includes(polygon.serviceId.toString())
          );
        });
        routePlan.push(
          ...routeGeneration(crewMemberId, filteredPolygonIds, lookUpData),
        );
      });
      setLineData(routePlan);
    }
  }, [crewMemberDayWise, lookUpData, selectedServiceIds]);
  
  return (
    <DeckGlOverlay
      layers={getDeckGlLayers(lineData, showConnectingLines)}
      typedArrayManagerProps={
        width < 800 ? { overAlloc: 1, poolSize: 0 } : null
      }
      isVisible={isVisible}
    />
  );
};

export default PlanningTransitionLines;
