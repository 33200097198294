import React, { useState } from "react";
import { useSelector } from "react-redux";
import useViewportWidth from "../../../Hooks/useViewportWidth";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import CrewMemberTimelinePlanner from "./CrewMemberTimelinePlanner";
import {
  Box,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { MdClose } from "react-icons/md";
import { getCustomColor } from "../../../utils/theme";
import ServiceWiseTimelinePlanner from "./ServiceWiseTimelinePlanner";

const PlannerTimelineDrawer = ({
  isPlannerDrawerOpen,
  isTimelineDrawerOpen,
  setIsTimelineDrawerOpen,
  drawerRef,
}) => {
  const [tabValue, setTabValue] = useState(0);
  const greenShade1 = getCustomColor("greenShade1");
  const lookUp = useSelector(
    (state) => state.planningDashboard.plannedLookUpData,
  );
  const width = useViewportWidth();
  return (
    <Drawer
      open={isTimelineDrawerOpen}
      direction="bottom"
      className="insidedrawer"
      lockBackgroundScroll={false}
      enableOverlay={false}
      style={{
        height: "auto",
        borderRadius: "10px",
        bottom: "2vh",
        right: "2vw",
        padding: "12px",
        left: isPlannerDrawerOpen && width > 768 ? "40vw" : "2vw",
        width: isPlannerDrawerOpen && width > 768 ? "58vw" : "94vw",
        border: "solid 1px #D0FFA7",
      }}
    >
      <Box className="gisTimelineHeader">
        {width > 500 ? (
          <>
            <Box>
              <Typography
                variant="h6"
                component="h6"
                fontWeight={600}
                color="#616965"
              >
                Gantt Chart
              </Typography>
            </Box>
            <Box
              sx={{ background: greenShade1.main }}
              className="timelineTabContainer"
            >
              <Box
                sx={{
                  backgroundColor: tabValue === 0 ? "primary.dark" : "white",
                  color: tabValue === 0 ? "white" : "black",
                  border: `1px solid ${tabValue === 0 ? "#4EA403" : "grey"}`,
                }}
                className="timelineTabButton"
                onClick={() => setTabValue(0)}
              >
                Crew
              </Box>
              <Box
                sx={{
                  backgroundColor: tabValue === 1 ? "primary.dark" : "white",
                  color: tabValue === 1 ? "white" : "black",
                  border: `1px solid ${tabValue === 1 ? "#4EA403" : "grey"}`,
                }}
                className="timelineTabButton"
                onClick={() => setTabValue(1)}
              >
                Services
              </Box>
            </Box>
          </>
        ) : (
          <Select
            value={tabValue}
            className="tabSelect"
            sx={{ marginBottom: "10px" }}
            onChange={(e) => setTabValue(e.target.value)}
            fullWidth
          >
            <MenuItem selected value={0}>
              Device Wise
            </MenuItem>
            <MenuItem value={1}>Service Wise</MenuItem>
          </Select>
        )}
        <IconButton
          onClick={() => setIsTimelineDrawerOpen(!isTimelineDrawerOpen)}
        >
          <MdClose />
        </IconButton>
      </Box>
      <Stack direction="row" gap={3} flexWrap="wrap" marginBottom={1}>
        {tabValue === 0 ? (
          <>
            {lookUp?.services &&
              Object.keys(lookUp?.services).map((serviceId) => (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.5rem",
                    }}
                  >
                    <span
                      style={{
                        borderRadius: "25%",
                        padding: "0.4rem",
                        backgroundColor: lookUp?.services[serviceId].color,
                      }}
                    ></span>
                    <Typography variant="body2" component="span">
                      {lookUp?.services[serviceId].serviceName}
                    </Typography>
                  </Box>
                </>
              ))}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              <span
                style={{
                  borderRadius: "25%",
                  padding: "0.4rem",
                  backgroundColor: "#7a7a7a",
                }}
              ></span>
              <Typography variant="body2" component="span">
                Back to Truck
              </Typography>
            </Box>
          </>
        ) : (
          lookUp?.crewMembers &&
          Object.keys(lookUp?.crewMembers).map((crewMemberId) => (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              <span
                style={{
                  borderRadius: "25%",
                  padding: "0.4rem",
                  backgroundColor: lookUp?.crewMembers[crewMemberId]?.color,
                }}
              ></span>
              <Typography variant="body2" component="span">
                {lookUp?.crewMembers[crewMemberId]?.name}
              </Typography>
            </Box>
          ))
        )}
      </Stack>
      <div ref={drawerRef}>
        <div className="graphContainer">
          <div style={{display: tabValue === 0 ? "block" : "none"}}>
            <CrewMemberTimelinePlanner />
          </div>
          <div style={{display: tabValue === 1 ? "block" : "none"}}>
            <ServiceWiseTimelinePlanner />
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default PlannerTimelineDrawer;
