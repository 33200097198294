import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { APIProvider } from "@vis.gl/react-google-maps";
import { Box, Typography } from "@mui/material";
import { IoMdArrowDropdown, IoMdArrowDropright } from "react-icons/io";
import DashboardSidebar from "./PlanningDashboardSidebar";
import PlanningDashboardGoogleMaps from "./PlanningDashboardGoogleMaps";
import { TbTimeline } from "react-icons/tb";
import { TiArrowSortedUp } from "react-icons/ti";
import PlannerTimelineDrawer from "./Components/PlannerTimelineDrawer";
import {
  setPlanningMapGeoJsonData,
  setPlanningPopupSelectedFilteredGeoJson,
} from "../../slices/planningDashboardSlice";
import {
  useGetRunPlannerSidebarDataQuery,
} from "../../services/OpenApi";
import { useLocation } from "react-router-dom";
import Pbf from "pbf";
import geobuf from "geobuf";
import LookupHeader from "./Components/LookupHeader";
import { isStatusPreSolving } from "./Components/utils";

const DashboardMapScreen = () => {
  const mapRef = useRef(null);
  const drawerRef = useRef();
  const dispatch = useDispatch();
  const [drawerHeight, setDrawerHeight] = useState(0);

  const user = JSON.parse(localStorage.getItem("user"));
  const isStreetView = useSelector((state) => state.global.isStreetView);
  const planningInputData = useSelector(
    (state) => state.planningDashboard.planningInputData,
  );
  const planningPopupSelectedFilteredGeoJson = useSelector(
    (state) => state.planningDashboard.planningPopupSelectedFilteredGeoJson,
  );
  const lookUp = useSelector(
    (state) => state.planningDashboard.plannedLookUpData,
  );
  const mapGeoJson = useSelector(
    (state) => state.planningDashboard.planningMapGeoJsonData,
  );
  const [isPlannerDrawerOpen, setIsPlannerDrawerOpen] = useState(true);
  const [isTimelineDrawerOpen, setIsTimelineDrawerOpen] = useState(false);
  const selectedPolygonIds = useSelector(
    (state) => state.global.selectedPolygonIds,
  );

  useEffect(() => {
    // Ensure the drawerRef is currently pointing to an element
    const drawerElement = drawerRef.current;
    if (!drawerElement) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setDrawerHeight(entry.contentRect.height);
      }
    });

    resizeObserver.observe(drawerElement);

    // Cleanup function to safely unobserve
    return () => {
      if (drawerElement) {
        resizeObserver.unobserve(drawerElement);
      }
    };
  }, [isTimelineDrawerOpen]);

  useEffect(() => {
    if (selectedPolygonIds && mapGeoJson) {
      const selectedIds = Object.keys(selectedPolygonIds).flatMap(
        (serviceName) =>
          selectedPolygonIds[serviceName]?.flatMap((parent) =>
            parent?.polygons?.map((polygon) => polygon?.pathId),
          ),
      );

      const filteredFeatures = mapGeoJson?.features?.filter((feature) =>
        selectedIds.includes(feature.properties.pathId),
      );
      dispatch(
        setPlanningPopupSelectedFilteredGeoJson({
          type: "FeatureCollection",
          features: filteredFeatures,
        }),
      );
    }
  }, [selectedPolygonIds, mapGeoJson]);

  const togglePlannerDrawer = () => {
    setIsPlannerDrawerOpen(!isPlannerDrawerOpen);
  };

  const fetchGeoBufData = async () => {
    fetch(
      `https://qa-topg-planner-gis.s3.ap-southeast-1.amazonaws.com/KMLResponse/${user?.organization}/${planningInputData?.v2PlannerName}_${planningInputData?.parcelId}_${planningInputData?.v2PlannerId}/planner.geobuf`,
      {
        headers: { Accept: "application/x-protobuf" },
      },
    )
      .then((response) => response.arrayBuffer())
      .then((buffer) => {
        const pbf = new Pbf(new Uint8Array(buffer));
        const geojson = geobuf.decode(pbf);
        if (geojson) {
          dispatch(setPlanningMapGeoJsonData(geojson));
        }
      })
      .catch((e)=>{
        console.error(e)
        dispatch(setPlanningMapGeoJsonData({
          features: [],
          type: "FeatureCollection"
        }));
      });
  };

  useEffect(() => {
    if (planningInputData) {
      fetchGeoBufData();
    }
  }, [planningInputData]);

  const handleViewTimelineButton = () => {
    setIsTimelineDrawerOpen(!isTimelineDrawerOpen);
  };

  const locationQuery = useLocation();
  const searchParams = new URLSearchParams(locationQuery.search);
  const plannerId = searchParams.get("id");
  const { data: runPlannerData, isLoading: isRunPlannerDataLoading } =
    useGetRunPlannerSidebarDataQuery({
      plannerId: plannerId,
      orgName: user?.organization,
    });
  const planningStatus = useSelector(
    (state) => state.planningDashboard.planningStatus,
  );

  return (
    <div className="mapPanelContainer">
      <div className="mapboxMapContainer">
        <LookupHeader runPlannerData={runPlannerData} />
        <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}>
          <PlanningDashboardGoogleMaps
          runPlannerData={runPlannerData}
            mapData={mapGeoJson}
            mapRef={mapRef}
            coveredFilteredGeoJson={planningPopupSelectedFilteredGeoJson}
          />
        </APIProvider>
      </div>
      <Box
        className="sidebarButton"
        sx={{ top: "50%", left: isPlannerDrawerOpen ? "max(27.5rem, 32vw)" : "0vw" }}
        onClick={togglePlannerDrawer}
      >
        <IoMdArrowDropright
          fontSize={26}
          style={{ transform: isPlannerDrawerOpen ? "rotate(180deg)" : "rotate(0deg)" }}
        />
      </Box>
      <DashboardSidebar
        togglePlannerDrawer={togglePlannerDrawer}
        isPlannerDrawerOpen={isPlannerDrawerOpen}
        runPlannerData={runPlannerData}
        isRunPlannerDataLoading={isRunPlannerDataLoading}
      />
      {!isStatusPreSolving(planningStatus) && lookUp && (
          <>
            {!isStreetView && (
              <>
                <button
                  className="viewTimelineButton"
                  onClick={handleViewTimelineButton}
                >
                  <TbTimeline size={20} />{" "}
                  <Typography>View Timeline </Typography> <TiArrowSortedUp />
                </button>
              </>
            )}
            {isTimelineDrawerOpen && (
              <div>
                <button
                  className={`closeTimelineButton`}
                  style={{
                    bottom: `${drawerHeight + 10}px`, // Adjust based on drawer's height
                  }}
                  onClick={() => setIsTimelineDrawerOpen(!isTimelineDrawerOpen)}
                >
                  <IoMdArrowDropdown />
                </button>
              </div>
            )}
            <PlannerTimelineDrawer
              isPlannerDrawerOpen={isPlannerDrawerOpen}
              isTimelineDrawerOpen={isTimelineDrawerOpen}
              setIsTimelineDrawerOpen={setIsTimelineDrawerOpen}
              drawerRef={drawerRef}
            />
          </>
        )}
    </div>
  );
};

export default DashboardMapScreen;
