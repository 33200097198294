
export function routeGeneration(comingPolygons, parkingId, data) {
  const polygons = comingPolygons.map((polygonId) => data?.polygons[polygonId]);
  const routePlan = [];
  if (polygons.length === 0) {
    return routePlan;
  }

  // Iterate through each polygon
  for (let i = 0; i < polygons.length; i++) {
    const polygon = polygons[i];
    const nextPolygon = polygons[i + 1];

    // If current polygon's fromParking is true, add parking's point as entry
    if (polygon?.fromParking) {
      if (polygon?.wayPoints && polygon?.wayPoints?.length > 0) {
        routePlan.push({
          entryPoint: data?.parkings[parkingId]?.point,
          exitPoint: polygon?.wayPoints[0],
        });
        for (let i = 0; i < polygon?.wayPoints?.length - 1; i++) {
          const wayPoint = polygon?.wayPoints[i];
          const nextWayPoint = polygon?.wayPoints[i + 1];
          routePlan.push({
            entryPoint: wayPoint,
            exitPoint: nextWayPoint,
          });
        }
        routePlan.push({
          entryPoint: polygon.wayPoints[polygon.wayPoints.length - 1],
          exitPoint: polygon.entryPoint,
        });
      } else {
        routePlan.push({
          entryPoint: data.parkings[parkingId].point,
          exitPoint: polygon.entryPoint,
        });
      }
    }

    // Add entry and exit points of current polygon if it's not the last one
    if (i !== polygons.length - 1) {
      // If current polygon's toParking is true, add parking's point as exit
      if (polygon.toParking) {
        if (polygon?.wayPoints && polygon?.wayPoints?.length > 0) {
          for (let i = 0; i < polygon?.wayPoints.length - 2; i++) {
            const wayPoint = polygon?.wayPoints[i];
            const nextWayPoint = polygon?.wayPoints[i + 1];
            routePlan.push({
              entryPoint: wayPoint,
              exitPoint: nextWayPoint,
            });
          }
        } else if (
          polygon?.toParkingWayPoints &&
          polygon?.toParkingWayPoints?.length > 0
        ) {
          routePlan.push({
            entryPoint: polygon?.exitPoint,
            exitPoint: polygon?.toParkingWayPoints[0],
          });
          for (let i = 0; i < polygon?.toParkingWayPoints.length - 1; i++) {
            const wayPoint = polygon?.toParkingWayPoints[i];
            const nextWayPoint = polygon?.toParkingWayPoints[i + 1];
            routePlan.push({
              entryPoint: wayPoint,
              exitPoint: nextWayPoint,
            });
          }
          routePlan.push({
            entryPoint:
              polygon?.toParkingWayPoints[
                polygon?.toParkingWayPoints?.length - 1
              ],
            exitPoint: polygon?.entryPoint,
          });
        } else {
          routePlan.push({
            entryPoint: polygon?.exitPoint,
            exitPoint: data?.parkings[parkingId]?.point,
          });
        }
      } else if (nextPolygon?.wayPoints && nextPolygon?.wayPoints?.length > 0) {
        for (let i = 0; i < nextPolygon.wayPoints.length - 1; i++) {
          const wayPoint = nextPolygon.wayPoints[i];
          const nextWayPoint = nextPolygon.wayPoints[i + 1];
          routePlan.push({
            entryPoint: wayPoint,
            exitPoint: nextWayPoint,
          });
        }
      } else {
        routePlan.push({
          entryPoint: polygon?.exitPoint,
          exitPoint: nextPolygon?.entryPoint,
        });
      }
    }
  }

  const lastPolygon = polygons[polygons.length - 1];

  if (lastPolygon?.toParking) {
    if (
      lastPolygon?.toParkingWayPoints &&
      lastPolygon?.toParkingWayPoints?.length > 0
    ) {
      routePlan.push({
        entryPoint: lastPolygon.exitPoint,
        exitPoint: lastPolygon.toParkingWayPoints[0],
      });
      for (let i = 0; i < lastPolygon?.toParkingWayPoints?.length - 1; i++) {
        const wayPoint = lastPolygon?.toParkingWayPoints[i];
        const nextWayPoint = lastPolygon?.toParkingWayPoints[i + 1];
        routePlan.push({
          entryPoint: wayPoint,
          exitPoint: nextWayPoint,
        });
      }
      routePlan.push({
        entryPoint:
          lastPolygon.toParkingWayPoints[
            lastPolygon.toParkingWayPoints.length - 1
          ],
        exitPoint: data.parkings[parkingId].point,
      });
    } else {
      routePlan.push({
        entryPoint: lastPolygon.exitPoint,
        exitPoint: data.parkings[parkingId].point,
      });
    }
  }

  return routePlan;
}
