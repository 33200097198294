import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useCreateRunPlannerMutation } from "../../services/OpenApi";
import { useNavigate } from "react-router-dom";
import { trimCustomCharacters } from "../../utils/common";

const AddRunPlannerFileNameModal = ({ isOpen, onClose, fileData }) => {
  const [fileName, setFileName] = useState("");
  const [error, setError] = useState("");
  const [createRunPlanner, { isLoading }] = useCreateRunPlannerMutation();
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const allowedCharactersRegex = /^[a-zA-Z0-9_.\-\/]*$/;

  const handleFileNameChange = (e) => {
    let value = e.target.value;
    value = value.replace(/ +/g, "_");
    if (!allowedCharactersRegex.test(value)) {
      setError(
        "Invalid characters detected. Allowed characters are: letters, numbers, '_', '-', '.', and '/'."
      );
    } else {
      setError("");
    }
    setFileName(value);
  };
  const handleFileSave = async () => {
    let updatedName = trimCustomCharacters(fileName, "_");
    setFileName(updatedName);
    if (!updatedName.trim()) {
      setError("File name is required");
      return;
    }

    if (!allowedCharactersRegex.test(updatedName)) {
      setError(
        "Invalid characters detected. Allowed characters are: letters, numbers, '_', '-', '.', and '/'."
      );
      return;
    }

    try {
      const response = await createRunPlanner({
        name: updatedName,
        v2PlannerId: fileData?.v2PlannerId,
        propertyName: fileData?.site,
        organisationName: user?.organization,
      }).unwrap();

      if (response?.data) {
        navigate(
          `/addServices?id=${response?.data}&v2PlannerId=${fileData?.v2PlannerId}`
        );
        setFileName(""); 
        onClose(); 
      }
    } catch (error) {
      console.error("Failed to create run planner:", error);
    }
  };

  const handleClose = () => {
    setFileName(""); 
    onClose(); 
  };

  return (
    <Dialog open={isOpen} disableRestoreFocus onClose={handleClose} fullWidth={true} maxWidth="xs">
      <DialogTitle>Add New Run Planner</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus={true}
          margin="normal"
          size="small"
          label="Run Planner Name"
          fullWidth
          value={fileName} 
          onChange={handleFileNameChange}
          error={!!error}
          helperText={error} 
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={isLoading}>
          Cancel
        </Button>
        <Button
          onClick={handleFileSave}
          disabled={isLoading || !fileName.trim() || !!error} // Disable if invalid
          variant="contained"
        >
          {isLoading && <CircularProgress size={18} thickness={5} />} Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddRunPlannerFileNameModal;
