import React, { useEffect, useRef, useState } from "react";
import "./NewScreen.css";
import {
  useGetRunPlannerSidebarDataQuery,
  useGetPlansQuery,
} from "../../services/OpenApi";
import BannerTop from "./components/BannerTop";
import PropertiesColumn from "./components/PropertiesColumn";
import PlansColumn from "./components/PlansColumn";
import PlanDetailsColumn from "./components/PlanDetailsColumn";

const NewProperties = () => {
  const PropertiesColumnRef = useRef();
  const user = JSON.parse(localStorage.getItem("user"));
  const [selectedProperty, setSelectedProperty] = useState({});
  const [selectedPropertyPlanner, setSelectedPropertyPlanner] = useState({});
  const [geoJsonData, setGeoJsonData] = useState(null);
  const [geoJsonError, setGeoJsonError] = useState(null);
  const {
    data: runPlannerData,
    error: runPlannerError,
    isFetching: runPlannerLoading,
  } = useGetRunPlannerSidebarDataQuery(
    {
      plannerId: selectedPropertyPlanner.v2runPlannerId,
      orgName: user?.organization,
    },
    { skip: !selectedPropertyPlanner.v2runPlannerId },
  );
  const handlePlannerNameUpdate = (updatedPlannerName) => {
    setSelectedPropertyPlanner((prev) => ({
      ...prev,
      runPlannerName: updatedPlannerName,
    }));
  };
 
  
  useEffect(() => {
    const fetchGeoJsonData = async () => {
      try {
        const url = `https://qa-topg-planner-gis.s3.ap-southeast-1.amazonaws.com/KMLResponse/${user?.organization}/${runPlannerData?.data.v2PlannerName}_${runPlannerData?.data.parcelId}_${runPlannerData?.data.v2PlannerId}/planner_squared.geojson`;
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error("Failed to fetch GeoJSON data");
        }
        const data = await response.json();
        setGeoJsonData(data);
        setGeoJsonError(null);
      } catch (error) {
        console.error("Error fetching GeoJSON data:", error);
        setGeoJsonError(error.message);
      }
    };
    if (runPlannerData?.data) {
      fetchGeoJsonData();
    }
  }, [JSON.stringify(runPlannerData?.data)]);

  const { data: selectedLegacyPropertyPlans, isLoading: isLoadingLegacyPlans } =
    useGetPlansQuery(
      {
        orderId: selectedProperty.id,
        orgName: user?.organization,
      },
      {
        skip: !selectedProperty.id || selectedProperty.isNewParcel,
      },
    );
  const [properties, setProperties] = useState([]);
  
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <BannerTop />
      <div style={{ display: "flex", flex: 1 }}>
        <PropertiesColumn
          properties={properties}
          setProperties={setProperties}
          selectedProperty={selectedProperty}
          setSelectedProperty={setSelectedProperty}
          setSelectedPropertyPlanner={setSelectedPropertyPlanner}
          ref ={PropertiesColumnRef}
        />
        <PlansColumn
          selectedProperty={selectedProperty}
          selectedLegacyPropertyPlans={selectedLegacyPropertyPlans?.data}
          isLoadingLegacyPlans={isLoadingLegacyPlans}
          selectedPropertyPlanner={selectedPropertyPlanner}
          setSelectedPropertyPlanner={setSelectedPropertyPlanner}
          setSelectedProperty={setSelectedProperty}
          setProperties={setProperties}
        />
        <PlanDetailsColumn
          selectedProperty={selectedProperty}
          setSelectedProperty={setSelectedProperty}
          selectedPropertyPlanner={selectedPropertyPlanner}
          runPlannerData={runPlannerData}
          runPlannerLoading={runPlannerLoading}
          runPlannerError={runPlannerError || geoJsonError}
          geoJsonData={geoJsonData}
          setSelectedPropertyPlanner={setSelectedPropertyPlanner}
          handlePlannerNameUpdate={handlePlannerNameUpdate}
        />
      </div>
    </div>
  );
};
export default NewProperties;
