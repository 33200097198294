import React, { useEffect, useState } from "react";
import { Map, useMap, useMapsLibrary } from "@vis.gl/react-google-maps";
import { useDispatch, useSelector } from "react-redux";
import { calculateBounds, calculateZoom } from "../../utils/mapZoomCalculation";
import { setIsStreetView } from "../../slices/globalSlice";
import PlannerBaseLayer from "./Layers/PlannerBaseLayer";
import PlannerSelectedLayers from "./Layers/PlannerSelectedLayers";
import PlanningMapHoveredPolygonLayer from "./Layers/PlanningMapHoveredPolygonLayer";
import {
  setAddParkingMode,
  setClickedPolygonParent,
  setPlanningSelectedCrewMembersPathId,
} from "../../slices/planningDashboardSlice";
import PlanningHoveredPolygonPopup from "./Layers/PlanningHoveredPolygonPopup";
import PlannerCrewMemberServiceLayer from "./Layers/PlannerCrewMemberServiceLayer";
import PlanningEntryPoints from "./Layers/PlanningEntryPoints";
import PlannerBaseLayerWithoutLookup from "./Layers/PlannerBaseLayerWithoutLookup";
import PlanningParkingsMarkers from "./Layers/PlanningParkingsMarkers";
import PlanningTransitionLines from "./Layers/PlanningTransitionLines";
import TimelineHoveredPolygonLayer from "./Layers/TimelineHoveredPolygonLayer";
import { Skeleton, Stack } from "@mui/material";
import {
  MapTypeControls,
  ZoomControls,
} from "../../components/ModernMapControls";
import PlannningMapControlsDrawer from "./Components/PlanningMapControlsDrawer";
import useViewportWidth from "../../Hooks/useViewportWidth";
import useViewportHeight from "../../Hooks/useViewportHeight";
import ParkingModal from "../../Modals/ParkingModal/ParkingModal";
import {
  calculateDistanceBetweenPoints,
  fetchAddressFromLatLong,
} from "../../utils/mapFunc";
import { useCreateRunPlannerParkingMutation } from "../../services/OpenApi";
import { toast } from "react-toastify";
import ConfirmationModal from "../../Modals/ConfirmationModal/ConfirmationModal";
import { RotateLoader } from "react-spinners";
import PlannerCrewMemberWiseDirectionArrows from "./Layers/PlannerCrewMemberWiseDirectionArrows";
import {
  APP_HEADER_HEIGHT,
  isStatusPreSolving,
  LOOKUP_HEADER_HEIGHT,
} from "./Components/utils";
import mapLoader from "./mapLoader.json";
import Lottie from "lottie-react";

const PlanningDashboardGoogleMapsSkeleton = () => {
  return (
    <>
      <Skeleton
        variant="rectangular"
        height={`calc(100vh - ${APP_HEADER_HEIGHT} - ${LOOKUP_HEADER_HEIGHT})`}
        width="100%"
        animation="wave"
      />
      <Lottie
        animationData={mapLoader}
        loop={true}
        className="gif-loader" 
        style={{
          top: `calc(50% + ${APP_HEADER_HEIGHT})`,
        }}
      />
    </>
  );
};

const PlanningDashboardGoogleMaps = ({
  mapData,
  coveredFilteredGeoJson,
  runPlannerData,
}) => {
  const [filteredGeoJson, setFilteredGeoJson] = useState(null);
  const [mapHoveredGeoJson, setMapHoveredgeoJson] = useState(null);
  const [hoveredPolygonData, setHoveredPolygonData] = useState(null);
  const showLabels = useSelector((state) => state.planningDashboard.showLabels);
  const planningSelectedCrewMembersId = useSelector(
    (state) => state.planningDashboard.planningSelectedCrewMembersId,
  );
  const planningSelectedCrewMembersPathId = useSelector(
    (state) => state.planningDashboard.planningSelectedCrewMembersPathId,
  );
  const lookUpData = useSelector(
    (state) => state.planningDashboard.plannedLookUpData,
  );
  const selectedServices = useSelector(
    (state) => state.planningDashboard.selectedServices,
  );
  const showNumberCircles = useSelector(
    (state) => state.planningDashboard.showNumberCircles,
  );
  const planningCrewMemberDayWiseFilteredData = useSelector(
    (state) => state.planningDashboard.planningCrewMemberDayWiseFilteredData,
  );
  const hoveredPolygonFromTimeline = useSelector(
    (state) => state.planningDashboard.hoveredPolygon,
  );
  const showConnectingLines = useSelector(
    (state) => state.planningDashboard.showConnectingLines,
  );
  const dispatch = useDispatch();

  const map = useMap();
  const mapsLibrary = useMapsLibrary("maps");

  const [activeMapType, setActiveMapType] = useState("roadmap");
  const changeMapType = (mapType) => {
    map.setMapTypeId(mapType);
    setActiveMapType(mapType);
  };
  const handleZoom = (isZoomedIn) => {
    if (isZoomedIn) {
      map.setZoom(map.getZoom() + 1);
    } else {
      map.setZoom(map.getZoom() - 1);
    }
  };

  const [viewport, setViewport] = useState({
    center: { lat: 40.7, lng: -74 },
    zoom: 12,
    width: 800,
    height: 400,
    bearing: 0,
    streetViewControl: true,
    fullscreenControl: false,
    fullscreenControlOptions: true,
  });

  // Update the viewport when mapData changes
  const windowWidth = useViewportWidth();
  const windowHeight = useViewportHeight();
  const getMapRectangle = () => {
    // Get the root font size from the computed style of the document's root element
    let rootFontSize = parseFloat(
      getComputedStyle(document.documentElement).fontSize,
    );
    // headerCombinedHeight = app header + lookup header height
    let headerCombinedHeight = window.innerHeight * 0.08 + rootFontSize * 3.2;
    return { height: windowHeight - headerCombinedHeight, width: windowWidth };
  };
  useEffect(() => {
    if (!mapData || !mapData.features || mapData.features.length === 0) {
      return;
    }
    const bounds = calculateBounds(mapData.features);
    const { height, width } = getMapRectangle();
    // using 100 as confidence interval so extreme end polygons don't touch map bounds
    const zoomLevel = calculateZoom(bounds, width - 100, height - 100);
    setViewport((prevViewport) => ({
      ...prevViewport,
      center: {
        lat: (bounds[1] + bounds[3]) / 2,
        lng: (bounds[0] + bounds[2]) / 2,
      },
      zoom: zoomLevel,
    }));
  }, [mapData, showLabels]);

  const handleClickedPolygon = (clickedData) => {
    if (clickedData?.object && clickedData?.object?.properties?.pathId) {
      dispatch(setClickedPolygonParent(clickedData?.object?.properties));
    }
  };

  const handleHoveredPolygon = (hoveredData, event) => {
    if (hoveredData?.object && hoveredData?.object?.properties?.pathId) {
      const selectedPolygonId = hoveredData?.object?.properties?.pathId;
      setHoveredPolygonData(hoveredData);

      const filteredFeatures = mapData?.features?.filter((feature) => {
        if (feature?.properties?.pathId) {
          return (
            feature?.properties?.pathId !== undefined &&
            feature?.properties?.pathId === selectedPolygonId
          );
        }
      });
      const filteredGeoJsonData = { ...mapData, features: filteredFeatures };
      if (filteredGeoJsonData) {
        setMapHoveredgeoJson(filteredGeoJsonData);
      } else {
        setMapHoveredgeoJson(null);
        setHoveredPolygonData(null);
      }
    } else {
      setMapHoveredgeoJson(null);
      setHoveredPolygonData(null);
    }
  };

  useEffect(() => {
    const filterGeoJson = () => {
      if (mapData && mapData?.features && planningSelectedCrewMembersPathId) {
        let filteredGeoJsonByCrewMember = [];

        for (const crewMemberId in planningSelectedCrewMembersPathId) {
          const pathIds = planningSelectedCrewMembersPathId[crewMemberId];

          const filteredFeatures = mapData.features.filter(
            (feature) => selectedServices[feature.properties.layerType],
          );

          const filteredFeaturesForPathId = filteredFeatures.filter((feature) =>
            pathIds.includes(feature.properties.pathId),
          );

          const updatedFeatureWithColor = filteredFeaturesForPathId.map(
            (feature) => {
              const updatedProperties = {
                ...feature.properties,
                color: lookUpData?.crewMembers[crewMemberId]?.color,
              };
              const featureWithColor = {
                ...feature,
                properties: updatedProperties,
              };

              return featureWithColor;
            },
          );

          filteredGeoJsonByCrewMember = [
            ...filteredGeoJsonByCrewMember,
            ...updatedFeatureWithColor,
          ];
        }
        const aggregatedFilteredGeoJson = {
          ...mapData,
          features: filteredGeoJsonByCrewMember,
        };
        setFilteredGeoJson(aggregatedFilteredGeoJson);
      }
    };

    filterGeoJson();
  }, [planningSelectedCrewMembersPathId, mapData, selectedServices]);

  useEffect(() => {
    if (planningSelectedCrewMembersId && lookUpData) {
      const crewMemberPolygons = {}; // Object to hold crewMemberId: [polygons]

      Object.keys(lookUpData.crewMemberWise).forEach((crewMemberId) => {
        const isSelected = planningSelectedCrewMembersId[crewMemberId];

        if (isSelected) {
          const crewMember = lookUpData.crewMemberWise[crewMemberId];
          crewMemberPolygons[crewMemberId] = [];

          crewMemberPolygons[crewMemberId] = crewMember?.polygons?.map(
            (polygonId) => polygonId.split("-").slice(1).join("-"),
          );
          if (crewMemberId == 0) {
            crewMemberPolygons[crewMemberId] = crewMember?.polygons?.map(
              (polygonId) => polygonId.split("-").slice(1).join("-"),
            );
          }
        }
      });
      dispatch(setPlanningSelectedCrewMembersPathId(crewMemberPolygons));
    }
  }, [planningSelectedCrewMembersId, lookUpData]);

  useEffect(() => {
    if (mapsLibrary && map) {
      const panorama = map?.getStreetView();
      panorama.addListener("visible_changed", () => {
        dispatch(setIsStreetView(panorama.getVisible()));
      });
    }
  }, [mapsLibrary, map]);

  const serviceNameToIdMap =
    lookUpData &&
    Object.entries(lookUpData?.services)?.reduce(
      (acc, [id, { serviceName }]) => {
        // Normalize the service names to match the format of the selectedServices keys
        const normalizedServiceName = serviceName
          .replace(/_/g, " ")
          .toLowerCase();
        acc[normalizedServiceName] = id;
        return acc;
      },
      {},
    );

  const selectedServiceIds =
    lookUpData &&
    selectedServices &&
    Object.keys(selectedServices)
      .filter((service) => selectedServices[service])
      .map((service) => serviceNameToIdMap[service?.toLowerCase()])
      .filter((id) => id !== undefined); // Ensure the service name was found in the map

  const getEntryPointForPolygonId = (polygonId) => {
    const polygon = lookUpData?.polygons[polygonId];

    if (polygon) {
      const entryPoint = polygon.centerPoint;
      return entryPoint;
    }

    return null;
  };

  const [hoveredGeoJson, setHoveredgeoJson] = useState(null);
  function extractPathId(string) {
    const parts = string.split("-");
    return parts.slice(1).join("-");
  }

  const lookUp = useSelector(
    (state) => state.planningDashboard.plannedLookUpData,
  );
  useEffect(() => {
    if (mapData && mapData?.features && hoveredPolygonFromTimeline) {
      const filteredFeatures = mapData.features.filter((feature) => {
        return (
          feature.properties.pathId ===
          extractPathId(hoveredPolygonFromTimeline.id)
        );
      });
      const filteredGeoJsonData = { ...mapData, features: filteredFeatures };
      setHoveredgeoJson(filteredGeoJsonData);

      if (
        filteredGeoJsonData?.features &&
        Array.isArray(filteredGeoJsonData?.features) &&
        filteredGeoJsonData?.features?.length > 0
      ) {
        let temp = filteredGeoJsonData?.features[0]?.properties;
        // transforming out temp to hoveredPolygonData (the values that're actually being used on the component)
        let lookupPolygonsWithPathIdKeys = lookUp?.polygons
          ? Object.keys(lookUp?.polygons).reduce((acc, curr) => {
              acc[curr.split("-").slice(1, 3).join("-")] = {
                ...lookUp?.polygons[curr],
                serviceName:
                  lookUp?.services[curr.split("-").slice(0, 1).join("-")]
                    ?.serviceName,
                serviceColor:
                  lookUp?.services[curr.split("-").slice(0, 1).join("-")]
                    ?.color,
                crewName:
                  lookUp?.crewMembers[lookUp?.polygons[curr]?.crewMemberId]
                    ?.name,
                crewEquipment:
                  lookUp?.equipments[lookUp?.polygons[curr]?.crewEquipmentId]
                    ?.type,
              };
              return acc;
            }, {})
          : {};
        setHoveredPolygonData({
          coordinate: temp.center,
          object: {
            properties: {
              pathId: temp.pathId,
              layerType: temp.layerType,
              fromLookup: lookupPolygonsWithPathIdKeys[temp.pathId],
            },
          },
        });
      }
    } else {
      setHoveredgeoJson(null);
      setHoveredPolygonData(null);
    }
  }, [hoveredPolygonFromTimeline, mapData]);

  const [addParkingModal, setAddParkingModal] = useState(false);
  const [addParkingData, setAddParkingData] = useState(null);
  const [createRunPlannerParking] = useCreateRunPlannerParkingMutation();
  const user = JSON.parse(localStorage.getItem("user"));
  const addParkingMode = useSelector(
    (state) => state.planningDashboard.addParkingMode,
  );
  const [confirmationModal, setConfirmationModal] = useState({
    open: false,
    parkingPointDistance: null,
  });
  const [activeParking, setActiveParking] = useState({});
  const handleAddParking = async (
    parkingName,
    parkingType,
    parkingAddress,
    parkingCoordinates,
  ) => {
    const { success } = await createRunPlannerParking({
      orgName: user?.organization,
      runPlannerId: runPlannerData?.data?.v2runPlannerId,
      payload: {
        parkingName: parkingName,
        parkingType: parkingType,
        address: parkingAddress,
        latitude: parkingCoordinates?.lat,
        longitude: parkingCoordinates?.lng,
      },
    }).unwrap();
    if (success) dispatch(setAddParkingMode(false));
  };
  const handleMapClick = async (event) => {
    if (addParkingMode) {
      const lat = event?.detail?.latLng?.lat;
      const lng = event?.detail?.latLng?.lng;

      // max bound validation
      if (runPlannerData?.data?.parkings?.length >= 5) {
        toast.error("You can add up to five parking locations only.");
        return;
      }

      // fetching address from lat long to be used in case user confirms the distance validation
      const coordinates = { lat, lng };
      const address = await fetchAddressFromLatLong(coordinates);
      setAddParkingData({
        ...coordinates,
        address,
      });

      // distance validation
      const viewPortCenter = viewport?.center;
      const distance = calculateDistanceBetweenPoints(
        [viewPortCenter.lng, viewPortCenter.lat],
        [lng, lat],
      );
      if (distance > 1) {
        setConfirmationModal({
          open: true,
          parkingPointDistance: distance,
        });
        return;
      }

      setAddParkingModal(true);
    } else {
      setActiveParking({});
    }
  };

  const planningStatus = useSelector(
    (state) => state.planningDashboard.planningStatus,
  );
  const planningMapGeoJsonData = useSelector(
    (state) => state.planningDashboard.planningMapGeoJsonData,
  );
  if (!planningStatus || !planningMapGeoJsonData)
    return <PlanningDashboardGoogleMapsSkeleton />;

  if (!isStatusPreSolving(planningStatus)) {
    if (!lookUpData) return <PlanningDashboardGoogleMapsSkeleton />;
  }

  return (
    <div
      style={{
        height: `calc(100vh - ${APP_HEADER_HEIGHT} - ${LOOKUP_HEADER_HEIGHT})`,
        width: "100%",
        cursor: addParkingMode ? "crosshair" : "",
      }}
      className="ready-to-plan-map"
    >
      {map && (
        <>
          <div
            style={{
              position: "absolute",
              top: `calc(${APP_HEADER_HEIGHT} + ${LOOKUP_HEADER_HEIGHT} + 1.05rem)`,
              right: "1rem",
              zIndex: 1,
              display: "flex",
              gap: "0.5rem",
            }}
          >
            <PlannningMapControlsDrawer />
          </div>
          <div
            style={{
              position: "absolute",
              bottom: "1.35rem",
              right: "0.5rem",
              zIndex: 1,
              display: "flex",
              gap: "0.5rem",
            }}
          >
            <Stack gap="0.5rem" direction="row">
              <ZoomControls handleZoom={handleZoom} />
              <MapTypeControls
                changeMapType={changeMapType}
                mapType={activeMapType}
              />
            </Stack>
          </div>
        </>
      )}
      <Map
        {...viewport}
        zoom={viewport?.zoom}
        defaultZoom={viewport?.zoom}
        center={viewport?.center}
        defaultCenter={viewport?.center}
        onCameraChanged={(v) => setViewport(v.detail)}
        gestureHandling={"greedy"}
        draggableCursor={addParkingMode ? "pointer" : "default"} // Change this to any cursor style you want when the map is idle
        draggingCursor="move"
        tiltInteractionEnabled={true}
        defaultTilt={0}
        // disableDefaultUI= {true}
        mapId={
          showLabels
            ? process.env.REACT_APP_GOOGLE_MAPS_WITH_LABELS_ID
            : process.env.REACT_APP_GOOGLE_MAPS_WITHOUT_LABELS_ID
        }
        defaultBounds={{
          south: 40.5,
          west: -74.2,
          north: 40.9,
          east: -73.8,
        }}
        zoomControl={false}
        mapTypeControl={false}
        stylers={true}
        onClick={(event) => handleMapClick(event)}
      >
        <TimelineHoveredPolygonLayer hoveredGeoJson={hoveredGeoJson} />
        {lookUpData ? (
          <PlannerBaseLayer
            coveredFilteredGeoJson={coveredFilteredGeoJson}
            handleHoveredPolygon={handleHoveredPolygon}
            handleClickedPolygon={handleClickedPolygon}
            filteredGeoJson={filteredGeoJson}
            shouldLowerOpacity={!!hoveredGeoJson}
          />
        ) : (
          <PlannerBaseLayerWithoutLookup
            layerData={mapData}
            handleHoveredPolygon={handleHoveredPolygon}
            handleClickedPolygon={handleClickedPolygon}
            coveredFilteredGeoJson={coveredFilteredGeoJson}
            shouldLowerOpacity={!!hoveredGeoJson}
          />
        )}

        <PlannerCrewMemberServiceLayer
          handleHoveredPolygon={handleHoveredPolygon}
          filteredGeoJson={filteredGeoJson}
        />
        <PlanningMapHoveredPolygonLayer hoveredGeoJson={mapHoveredGeoJson} />
        <PlanningHoveredPolygonPopup
          hoveredPolygonData={hoveredPolygonData}
          setHoveredPolygonData={setHoveredPolygonData}
          isHoveredPolygonFromTimeline={!!hoveredPolygonFromTimeline}
        />
        <PlannerSelectedLayers
          layerData={coveredFilteredGeoJson}
          dimLayers={false}
          //   isSummary={isSummary}
        />
        <PlanningTransitionLines
          crewMemberDayWise={planningCrewMemberDayWiseFilteredData}
          lookUpData={lookUpData}
          showConnectingLines={showConnectingLines}
          selectedServiceIds={selectedServiceIds}
        />
        <PlannerCrewMemberWiseDirectionArrows
          filteredGeoJson={filteredGeoJson}
          crewMemberDayWise={planningCrewMemberDayWiseFilteredData}
          viewport={viewport}
          lookUpData={lookUpData}
          position={90}
          selectedServiceIds={selectedServiceIds}
          getEntryPointForPolygonId={getEntryPointForPolygonId}
        />
        <PlanningEntryPoints
          filteredGeoJson={filteredGeoJson}
          crewMemberDayWise={planningCrewMemberDayWiseFilteredData}
          selectedServiceIds={selectedServiceIds}
          showNumberCircles={showNumberCircles}
          getEntryPointForPolygonId={getEntryPointForPolygonId}
        />
        <PlanningParkingsMarkers
          runPlannerData={runPlannerData}
          isPolygonInfoWindowOpen={!!hoveredPolygonData}
          activeParking={activeParking}
          setActiveParking={setActiveParking}
        />
      </Map>
      <ParkingModal
        isOpen={addParkingModal}
        setIsOpen={setAddParkingModal}
        parkingAddressVal={addParkingData?.address}
        parkingCoordinates={addParkingData}
        submitFunction={handleAddParking}
        editData={null}
      />
      <ConfirmationModal
        isOpen={confirmationModal.open}
        setIsOpen={(newVal) =>
          setConfirmationModal({ ...confirmationModal, open: newVal })
        }
        body={`Parking point is ${confirmationModal.parkingPointDistance} miles away from the parcel. Do you still want to add it?`}
        PrimaryButtonTitle="Confirm"
        handleOk={() => {
          setConfirmationModal({ ...confirmationModal, open: false });
          setAddParkingModal(true);
        }}
      />
    </div>
  );
};

export default PlanningDashboardGoogleMaps;
