import React, { useEffect, useState } from "react";
import { APIProvider } from "@vis.gl/react-google-maps";
import ColumnHeader from "./ColumnHeader";
import PlanMap from "./PlanMap";
import { RiDeleteBinLine } from "react-icons/ri";
import { HiArrowsExpand } from "react-icons/hi";
import {
  Button,
  Popover,
  Typography,
  Box,
  Tooltip,
  IconButton,
  Skeleton,
} from "@mui/material";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  useDeleterunPlannerMutation,
  useUpdateRunPlannerNameMutation,
} from "../../../services/OpenApi";
import { Link } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import { getCustomColor } from "../../../utils/theme";
import { IoEyeOutline } from "react-icons/io5";
import { trimCustomCharacters } from "../../../utils/common";
const PlanDetailsColumn = ({
  selectedProperty,
  selectedPropertyPlanner,
  runPlannerLoading,
  runPlannerError,
  runPlannerData,
  geoJsonData,
  setSelectedPropertyPlanner,
  setSelectedProperty,
}) => {
  const user = useSelector((state) => state.global.user);
  const [deleteNewPlan] = useDeleterunPlannerMutation();
  const [updateRunPlannerName] = useUpdateRunPlannerNameMutation();
  const handleDelete = async () => {
    try {
      const response = await deleteNewPlan({
        orgName: user?.organization,
        runPlannerId: selectedPropertyPlanner.v2runPlannerId,
      });
      if (response?.error) {
        toast.error(response?.error?.error || response?.error?.data?.message, {
          autoClose: 2000,
          hideProgressBar: true,
        });
      } else {
        setSelectedProperty((prev) => ({
          ...prev,
          v2RunPlannerDtoList: prev.v2RunPlannerDtoList.filter(
            (plan) =>
              plan.v2runPlannerId !== selectedPropertyPlanner.v2runPlannerId,
          ),
        }));
        toast.success("Plan deleted successfully", {
          autoClose: 1000,
          hideProgressBar: true,
        });
        handleClose();
      }
    } catch (err) {
      console.log("error => ", err);
    }
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleConfirmDelete = () => {
    if (selectedPropertyPlanner?.v2PlannerId) {
      handleDelete();
      setSelectedPropertyPlanner({});
    }
    setAnchorEl(null);
  };
  const [isEditing, setIsEditing] = useState(false);
  const [editedPlannerName, setEditedPlannerName] = useState(
    selectedPropertyPlanner?.runPlannerName || runPlannerData?.data?.runPlannerName || "",
  );

  const handleEditClick = () => {
    setIsEditing(true);
    setEditedPlannerName(selectedPropertyPlanner?.runPlannerName || runPlannerData.data.runPlannerName);
  };

  const handleSaveClick = async () => {
    const allowedCharactersRegex = /^[a-zA-Z0-9_.\-\/]*$/;
    try {
      let updatedName = trimCustomCharacters(editedPlannerName, "_");
      setEditedPlannerName(updatedName);
      if (!updatedName.trim()) {
        toast.error("Cannot save an empty name");
        return;
      }

      if (!allowedCharactersRegex.test(updatedName)) {
        toast.error(
          "Invalid characters detected. Allowed characters are: letters, numbers, '_', '-', '.', and '/'."
        );
        return;
      }
      const response = await updateRunPlannerName({
        orgName: user?.organization,
        runPlannerId: selectedPropertyPlanner.v2runPlannerId,
        runPlannerName: updatedName,
      });

      if (response?.error) {
        throw new Error(response.error.message);
      }
      toast.success("Planner name updated successfully");
      setIsEditing(false);
      setSelectedPropertyPlanner((prev) => ({
        ...prev,
        runPlannerName: updatedName,
      }));
      setSelectedProperty((prev) => ({
        ...prev,
        v2RunPlannerDtoList: prev.v2RunPlannerDtoList.map((item) => {
          if (item.v2runPlannerId === selectedPropertyPlanner.v2runPlannerId)
            return {
              ...item,
              runPlannerName: updatedName,
            };
          else return item;
        }),
      }));
    } catch (err) {
      console.error(err);
      toast.error("Failed to update planner name");
    }
  };
  const handleInputChange = (e) => {
    let value = e.target.value;
    value = value.replace(/ +/g, "_");
    setEditedPlannerName(value);
  };
  const getStatusDetails = (status) => {
    const statusMap = {
      INITIALIZING: {
        message: "Starting Planning",
        tooltip: "Indicates the process is in the initialization phase.",
      },
      SOLVING: {
        message: "Planning",
        tooltip: "Indicates the process is solving the problem.",
      },
      EXCEPTION_TERMINATED: {
        message: "Stopped",
        tooltip: "Indicates the process has been terminated due to some error.",
      },
      SOLVING_STOPPED: {
        message: "Halted",
        tooltip: "Indicates the process has been stopped by the user.",
      },
      NOT_SOLVING: {
        message: "Not Started",
        tooltip: "Indicates the planning process has not started yet.",
      },
      SOLVING_SCHEDULED: {
        message: "Planned",
        tooltip: "Indicates planning has been completed.",
      },
    };

    return statusMap[status] || { message: status, tooltip: "" };
  };
  const currentStatus = runPlannerData?.data?.currentStatus;
  const { message, tooltip } = getStatusDetails(currentStatus);

  useEffect(() => {
    setIsEditing(false);
  }, [runPlannerData]);
  return (
    <div style={{ width: "40%" }}>
      <ColumnHeader>Plan Details</ColumnHeader>
      <div
        className="modern-scroll"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: "0.5rem",
          // 100vh - header height - (column header height + padding)
          height: "calc(100vh - 8vh - 11.85rem)",
          overflowY: "auto",
        }}
      >
        {selectedPropertyPlanner?.v2PlannerId ? (
          <>
            {runPlannerLoading && (
              <Box sx={{ padding: "0.5rem" }}>
                <Skeleton height={400} style={{ marginTop: "-6rem" }} />
                <Skeleton
                  height={20}
                  width={100}
                  style={{ marginTop: "-4rem" }}
                />
                <Skeleton height={20} width={100} />
                <Skeleton height={20} width={350} />
                <Skeleton height={20} width={100} />
                <Skeleton height={20} width={350} />
                <Skeleton height={20} width={100} />
                <Skeleton height={20} width={350} />
                <Skeleton height={20} width={100} />
              </Box>
            )}
            {runPlannerError && (
              <Typography
                variant="caption"
                component="p"
                sx={{
                  padding: "0.5rem 0.5rem",
                }}
              >
                Error: {runPlannerError}
              </Typography>
            )}
            {!runPlannerLoading && !runPlannerData && (
              <Typography
                variant="caption"
                component="p"
                sx={{
                  padding: "0.5rem 0.5rem",
                }}
              >
                No properties available.
              </Typography>
            )}
            {!runPlannerLoading && runPlannerData && (
              <div className="planDetailsBox">
                <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}>
                  <PlanMap geoJsonData={geoJsonData} />
                </APIProvider>
                <h3
                  className="tableHeading"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                  }}
                >
                  {isEditing ? (
                    <input
                      type="text"
                      value={editedPlannerName}
                      onChange={handleInputChange}
                      style={{
                        fontSize: "1rem",
                        padding: "0.2rem",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                        width: "150px",
                      }}
                    />
                  ) : (
                    <span>
                      {selectedPropertyPlanner.runPlannerName ||
                        runPlannerData?.data?.runPlannerName}
                    </span>
                  )}
                  <Tooltip title={isEditing ? "" : "Edit"}>
                    <IconButton
                      onClick={isEditing ? handleSaveClick : handleEditClick}
                    >
                      {isEditing ? (
                        <button
                          style={{
                            backgroundColor: "#62d100",
                            color: "black",
                            border: "none",
                            borderRadius: "4px",
                            padding: "5px 10px",
                          }}
                        >
                          Save
                        </button>
                      ) : (
                        <MdEdit color={"#62d100"} fontSize={15} />
                      )}
                    </IconButton>
                  </Tooltip>
                </h3>
                <Tooltip title={tooltip}>
                  <p
                    className="horizontalList"
                    style={{
                      fontSize: 11,
                      backgroundColor: "#DBF4FF",
                      borderRadius: "10px",
                      color: "#004563",
                      padding: "2px 10px",
                      display: "inline-block",
                    }}
                  >
                    Status: {message}
                  </p>
                </Tooltip>
                <p className="text">Services:</p>
                <ul className="horizontalList">
                  {runPlannerData.data.runPlannerServices.map(
                    (service, idx) => (
                      <li
                        key={idx}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <span
                          style={{
                            display: "inline-block",
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            backgroundColor: service.color
                              ? service.color
                              : "#CCCCCC",
                            marginRight: "5px",
                          }}
                        />
                        {service.coreServiceName}
                      </li>
                    ),
                  )}
                </ul>
                <p className="text">Crew:</p>
                <ul className="horizontalList">
                  {runPlannerData.data.crewMember.length > 0 ? (
                    runPlannerData.data.crewMember.map((crew, idx) => (
                      <li key={idx}>{crew.crewMemberName}</li>
                    ))
                  ) : (
                    <li>No Crew Member</li>
                  )}
                </ul>
                <p className="text">Equipment:</p>
                <ul className="horizontalList">
                  {runPlannerData.data.equipmentData.map((equip, idx) => (
                    <li key={idx}>
                      {equip.name} x {equip.quantity}
                    </li>
                  ))}
                </ul>
                <div
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  className="text"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <div>
                        Created By :
                        <span
                          style={{
                            fontFamily: "Albert Sans",
                            fontSize: "11px",
                            fontWeight: "400",
                            lineHeight: "15.4px",
                            textAlign: "left",
                          }}
                        >
                          {runPlannerData.data.createdBy}
                        </span>
                        <br />
                        Created At :
                        <span
                          style={{
                            fontFamily: "Albert Sans",
                            fontSize: "11px",
                            fontWeight: "400",
                            lineHeight: "15.4px",
                          }}
                        >
                          {new Date(
                            runPlannerData.data.createdAt,
                          ).toLocaleString()}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.5rem",
                      position: "fixed",
                      bottom: "0.5rem",
                      right: "0.5rem",
                    }}
                  >
                    <Tooltip title="Delete">
                    <button
                      style={{
                        color: "#B62359",
                        backgroundColor: "white",
                        border: "1px solid #BDC0BE",
                        borderRadius: "5px",
                        padding: "2px 5px",
                        cursor: "pointer",
                      }}
                      onClick={handleDeleteClick}
                    >
                      <RiDeleteBinLine fontSize={28} />
                    </button>
                    </Tooltip>
                    <Tooltip title="View Summary">
                    <Link
                      to={`/summary/${selectedProperty.id}`}
                      style={{
                        color: "#2F3733",
                        backgroundColor: "#62D100",
                        border: "1px solid #62D100",
                        borderRadius: "5px",
                        padding: "2px 5px",
                        cursor: "pointer",
                      }}
                    >
                      <HiArrowsExpand size={28} />
                    </Link>
                    </Tooltip>
                    <Tooltip title="View Run Plann">
                    <Link to={`/addServices?id=${selectedPropertyPlanner.v2runPlannerId}&v2PlannerId=${selectedPropertyPlanner.v2PlannerId}`}>
                      <button
                        style={{
                          color: "#2F3733",
                          backgroundColor: "#62D100",
                          border: "1px solid #62D100",
                          borderRadius: "5px",
                          padding: "2px 5px",
                          cursor: "pointer",
                        }}
                      >
                        <IoEyeOutline fontSize={28} />
                      </button>
                    </Link>
                    </Tooltip>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <Typography
            variant="caption"
            component="p"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              width: "100%",
            }}
          >
            <img
              src="/images/noProperty.png"
              alt="No Property"
              style={{
                maxWidth: "70%",
                maxHeight: "100%",
                width: "auto",
                height: "auto",
              }}
            />
          </Typography>
        )}
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Box p={2}>
            <Typography variant="body2">
              Are you sure you want to delete this plan?
            </Typography>
            <Box mt={2} display="flex" justifyContent="flex-end" gap="0.5rem">
              <Button variant="outlined" size="small" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                variant="contained"
                size="small"
                color="error"
                onClick={handleConfirmDelete}
              >
                Delete
              </Button>
            </Box>
          </Box>
        </Popover>
      </div>
    </div>
  );
};

export default PlanDetailsColumn;
