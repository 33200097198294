import React from 'react';
import { Box, Typography} from '@mui/material';
import { useNavigate } from "react-router-dom";
import { getCustomColor } from '../../utils/theme';
const MaintenancePage = () => {
    const blackShade1 = getCustomColor("blackShade1");
  const maintenanceStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#f7f7f7',
    textAlign: 'center',
  };

  const headingStyle = {
    fontSize: '36px',
    fontWeight: 'bold',
    color: blackShade1.main
  };

  const paragraphStyle = {
    fontSize: '18px',
    marginTop: '10px',
    color: blackShade1.light
  };
  return (
    <Box sx={maintenanceStyle}>
        <img
              src="/images/underMaintainence.png"
              alt="No Property"
              style={{
                width: "450px",
                height: "400px",
              }}
            />
      <Typography sx={headingStyle}>Site Under Maintenance</Typography>
      <Typography sx={paragraphStyle}>The site is currently under maintenance. Please try again later.</Typography>
    </Box>
  );
};

export default MaintenancePage;
