import React from "react";
import { Typography, IconButton } from "@mui/material";
import { MdDelete, MdEdit } from "react-icons/md";
import { getCustomColor } from "../../../utils/theme";

const ParkingCard = ({ parking, onEdit, onDelete }) => {
  const blackShade1 = getCustomColor("blackShade1");
  return (
    <div className="parkingCardContent">
      <div>
        <Typography sx={{ color: blackShade1.main }} fontWeight="600" variant="h6">{parking.parkingName}</Typography>
        <Typography sx={{ color: blackShade1.main }} fontWeight="600" variant="subtitle1">Type - {parking.parkingType}</Typography>
        <Typography sx={{ color: blackShade1.main }} className="parkingAddress" variant="body2">{parking.address}</Typography>
      </div>
      <div  style={{
          display: "flex",
          flexDirection: "row",
        }}>
        <IconButton color="primary" onClick={() => onEdit(parking.id)}>
          <MdEdit fontSize={15}/>
        </IconButton>
        <IconButton color="secondary" onClick={() => onDelete(parking.id)}>
          <MdDelete fontSize={15}/>
        </IconButton>
      </div>
    </div>
  );
};

export default ParkingCard;
