import React, { useEffect, useState } from "react";
import { PathLayer } from "@deck.gl/layers";
import { DeckGlOverlay } from "../../utils/deckgl-overlay";
import useViewportWidth from "../../Hooks/useViewportWidth";
function getDeckGlLayers(lineData) {
  if (!lineData) return [];

  return [
    new PathLayer({
      id: "dashed-path-layer",
      data: lineData,
      getPath: (d) => [d.source, d.target], // Adjust according to your data structure
      getColor: (d) => d.color,
      getWidth: 0.2,
      getDashArray: [5, 5],
      dashJustified: true,
      dashGapPickable: true,
      widthMinPixels: 2,
      visible: true,
      pickable: false,
    }),
  ];
}

const ConnectingLines = ({ connectingLinesData, crewMembers, forHover }) => {
  const [lineData, setLineData] = useState([]);
  const [isVisible, setIsVisible] = useState(true);
  const width = useViewportWidth();

  // this visible functionality is added due to webgl context lost issue
  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsVisible(!document.hidden);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  // data calculation for dotted lines

  useEffect(() => {
    if (forHover) {
      setLineData(connectingLinesData);
    } else {
      if (connectingLinesData) {
        const transformedArray = Object.entries(connectingLinesData).flatMap(
          ([crewMemberId, crewData]) =>
            crewMembers[crewMemberId]
              ? crewData.slice(0, -1).map((_, i) => ({
                  source: [crewData[i].lon, crewData[i].lat],
                  target: [crewData[i + 1].lon, crewData[i + 1].lat],
                }))
              : []
        );

        setLineData(transformedArray);
      }
    }
  }, [connectingLinesData, crewMembers]);
  return (
    <DeckGlOverlay
      layers={getDeckGlLayers(lineData)}
      typedArrayManagerProps={
        width < 800 ? { overAlloc: 1, poolSize: 0 } : null
      }
      isVisible={isVisible}
    />
  );
};

export default ConnectingLines;
