import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setPlannedLookUpData, setPlanningInputData, setPlanningMapGeoJsonData, setPlanningStatus, setShowConnectingLines, setShowNumberCircles } from "../../slices/planningDashboardSlice";
import DashboardMapScreen from "./PlanningDashboardMapScreen";

const PlanningDashboard = () => {
  const dispatch = useDispatch();
  
  // clear lookUp when unmounting component of this page
  useEffect(()=>{
    return () => {
      // optimizes case of persisting lookUp data
      // when switching between a planned plan to unplanned
      dispatch(setPlannedLookUpData(null));
      dispatch(setShowNumberCircles(false));
      dispatch(setShowConnectingLines(false));
      dispatch(setPlanningStatus(null));
      dispatch(setPlanningInputData(null));
      dispatch(setPlanningMapGeoJsonData(null));
    }
  }, [])

  return (
    <DashboardMapScreen />
  );
};

export default PlanningDashboard;
