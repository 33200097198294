export const STATUS_MAP = {
    NOT_SOLVING: "NOT_SOLVING",
    INITIALIZING: "INITIALIZING",
    SOLVING: "SOLVING",
    SOLVING_SCHEDULED: "SOLVING_SCHEDULED",
    EXCEPTION_TERMINATED: "EXCEPTION_TERMINATED",
    SOLVING_STOPPED: "SOLVING_STOPPED",
};

export const isStatusPreSolving = (status) => {
    return status === STATUS_MAP.NOT_SOLVING || status === STATUS_MAP.INITIALIZING;
}

export const isStatusMidSolving = (status) => {
    return status === STATUS_MAP.SOLVING || status === STATUS_MAP.INITIALIZING;
}

export const isStatusDoneSolving = (status) => {
    return status === STATUS_MAP.SOLVING_SCHEDULED || status === STATUS_MAP.EXCEPTION_TERMINATED || status === STATUS_MAP.SOLVING_STOPPED;
}

export const LOOKUP_HEADER_HEIGHT = "3.2rem";
export const APP_HEADER_HEIGHT = "8vh";