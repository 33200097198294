import React, { useEffect, useRef, useState } from "react";
import { Timeline } from "vis-timeline/standalone";
import "vis/dist/vis.min.css";
import { addHours, endOfDay, startOfDay } from "date-fns";
import {
  convertSecondsToReadable,
  convertStartAndEndTimes,
  convertTimestampToFormattedDate,
} from "../../../utils/common";
import { useDispatch, useSelector } from "react-redux";
import { DataSet } from "vis-data";
import { setHoveredPolygon } from "../../../slices/planningDashboardSlice"

const ServiceWiseTimelinePlanner = () => {
  const timelineRef = useRef();
  const dispatch = useDispatch();
  const lookUp = useSelector(
    (state) => state.planningDashboard.plannedLookUpData,
  );
  const selectedDay = useSelector((state) => state.global.selectedDay);
  const [groupData, setGroupData] = useState([]);
  const [itemsData, setItemsData] = useState([]);
  const [options, setOptions] = useState({
    stack: false,
    maxHeight: "32vh",
    zoomMin: 10000,
    orientation: { axis: "top", item: "top" },
    groupOrder: "content",
    showTooltips: true,
    preferZoom: true,
    verticalScroll: true,
    groupHeightMode: "fitItems",
    xss: {
      disabled: true
    },
    tooltip: {
      overflowMethod: "cap"
    }
  });

  const getCrewMemberName = (crewMemberId) => {
    const crewData = lookUp?.crewMembers;
    if (crewData[crewMemberId]) {
      return crewData[crewMemberId].name;
    } else {
      return "Crew member not found";
    }
  };

  const getServiceName = (serviceId) => {
    const servicesData = lookUp?.services;
    if (servicesData[serviceId]) {
      return servicesData[serviceId].serviceName;
    } else {
      return "Service not found";
    }
  };
  const getEquipmentName = (crewEquipmentId) => {
    const equipments = lookUp?.equipments;
    if (equipments[crewEquipmentId]) {
      return equipments[crewEquipmentId].type;
    } else {
      return "Equipment not found";
    }
  };

  const transformDataForTimeline = (lookup) => {
    const crewMemberWise = lookup.crewMemberWise;
    const timelineData = [];
    const timelineSummaryData = {};

    for (const crewId in crewMemberWise) {
      const crewData = crewMemberWise[crewId];
        let task = {}
        crewData?.polygons.forEach((polygonId) => {
          const polygon = lookup.polygons[polygonId];
          const crewMemberName = getCrewMemberName(polygon.crewMemberId);
          const serviceName = getServiceName(polygon?.serviceId);
          const equipmentName = getEquipmentName(polygon.crewEquipmentId);
          const color = lookup?.crewMembers[polygon?.crewMemberId]?.color;
          task = {
            id: polygonId,
            content: `<span style="font-size:12px">${polygonId}</span>`,
            start: convertTimestampToFormattedDate(polygon.arrivalTime),
            end: convertTimestampToFormattedDate(polygon.departureTime),
            group: `${crewMemberName} ${serviceName} ${polygon?.serviceId}`,
            title: `<div>
              <div class="individualContainer">
              <div>Crew Member : ${crewMemberName}</div>
              <div>Service Name : ${serviceName.replace("_", " ")}</div>
              <div>Equipment Name : ${equipmentName.replace("_", " ")}</div>
              <div>Start Time : ${convertTimestampToFormattedDate(
                polygon.arrivalTime,
              )}</div>
              <div>End Time : ${convertTimestampToFormattedDate(
                polygon.departureTime,
              )}</div>
              <div>${polygon?.measurementType || "Dimensions"} : ${polygon.measurement ? (`${polygon.measurement} ${polygon?.measurementType==="Length" ? "ft" : "sqft"}`) : "N/A"}</div>
              </div>
              </div>`,
              style: `border-color: ${color}; background-color: ${color}; color:white;`,
          };
          if(!timelineSummaryData[polygon?.serviceId]) {
            // first polygon of this serviceId
            const serviceDetails = lookUp?.services[polygon?.serviceId]
            console.log(serviceDetails)
            timelineSummaryData[polygon?.serviceId] = {
              ...task,
              id: "parent_"+task.id,
              group: polygon?.serviceId,
              content: "",
              title: `<div>
              <div class="individualContainer">
                <div>Total Time Work : ${convertSecondsToReadable(serviceDetails?.workTime)}</div>
                <div>Total Time Travel : ${convertSecondsToReadable(serviceDetails?.travelTime)}</div>
              </div>`,
              style: `border-color: ${serviceDetails?.color}; background-color: ${serviceDetails?.color}; color:white;`,
              crewData: crewData,
          };
          } else {
            // change end time if found polygon with higher end time
            if(timelineSummaryData[polygon?.serviceId].end < task.end) {
              timelineSummaryData[polygon?.serviceId].end = task.end;
            }
            // add crew data
          }
          
          timelineData.push(task);
        });
    }
    Object.keys(timelineSummaryData).forEach(serviceId=>{
      timelineData.push(timelineSummaryData[serviceId]);
    });
    return timelineData;
  };

  const transformGroupDataForTimeline = (lookup) => {
    const services = lookup.services;
    const groupData = [];

    const crewMemberWise = lookup.crewMemberWise;
    for (const crewId in crewMemberWise) {
      const crewData = crewMemberWise[crewId];
      
      crewData?.polygons.forEach((polygonId) => {
          const polygon = lookup.polygons[polygonId];
          const crewMemberName = getCrewMemberName(polygon.crewMemberId);
          const uniqueId = `${crewMemberName} ${getServiceName(
            polygon?.serviceId,
          )} ${polygon?.serviceId}`;

          const group = {
            id: uniqueId,
            content: `${crewMemberName}`,
            // className: 'timeline-event', // You can define custom CSS classes here
          };
          const groupExists = groupData.some(
            (existingGroup) => existingGroup.id === uniqueId,
          );

          if (!groupExists) {
            groupData.push(group);
          }
        });
    }

    for (const serviceId in services) {
      const serviceColor= services[serviceId]?.color
      const nestedGroups = [];
      const group = {
        id: serviceId,
        content: `<div style="display:flex;align-items:center;gap:0.5rem">
          ${getServiceName(serviceId).replace("_", " ")}
          <span style="background-color:${serviceColor};height:0.8rem;width:0.8rem;border-radius:50%">
          </span>
        </div>`,
        showNested: false,
      };
      groupData.forEach((item) => {
        if (item.id.includes(getServiceName(serviceId))) {
          nestedGroups.push(item.id);
        }
      });

      const containsName = groupData.some((item) =>
        item.id.includes(getServiceName(serviceId)),
      );

      if (containsName) {
        group.nestedGroups = nestedGroups;
      }
      groupData.push(group);
    }

    return groupData;
  };

  const findMinMaxTimeFromLookup = (lookup) => {
    let minStartTime = null;
    let maxEndTime = null;
    const crewMemberWise = lookup.crewMemberWise;
  
    for (const crewId in crewMemberWise) {
      const crewData = crewMemberWise[crewId];
      crewData?.polygons.forEach((polygonId) => {
        const polygon = lookup.polygons[polygonId];
  
        // Ensure arrivalTime and departureTime are valid numbers before processing
        if (polygon?.arrivalTime && !isNaN(polygon.arrivalTime)) {
          const startDate = convertTimestampToFormattedDate(polygon.arrivalTime);
  
          if ((!minStartTime || startDate < minStartTime) && startDate) {
            minStartTime = startDate;
          }
        }
  
        if (polygon?.departureTime && !isNaN(polygon.departureTime)) {
          const endDate = convertTimestampToFormattedDate(polygon.departureTime);
  
          if ((!maxEndTime || endDate > maxEndTime) && endDate) {
            maxEndTime = endDate;
          }
        }
      });
    }
  
    return { minStartTime, maxEndTime };
  };

  useEffect(() => {
    if (lookUp) {
      let lookupData = { ...lookUp };
      const timelineData = transformDataForTimeline(lookupData);
      setItemsData(timelineData);
      const groupData = transformGroupDataForTimeline(lookupData);
      setGroupData(groupData);
      const bounds = findMinMaxTimeFromLookup(lookupData);
      const chartStart = new Date(bounds.minStartTime);
      const chartEnd = new Date(bounds.maxEndTime);
      const adjustedTimes = convertStartAndEndTimes(chartStart, chartEnd);
      const clusterOpts = {
        start: adjustedTimes.start,
        end: addHours(adjustedTimes.end, 1),
        min: startOfDay(adjustedTimes.start),
        max: endOfDay(adjustedTimes.end),
      };
      setOptions((prevOptions) => ({
        ...prevOptions,
        ...clusterOpts,
      }));
    }
  }, [lookUp, selectedDay]);

  useEffect(() => {
    if (itemsData && groupData) {
      const items = new DataSet(itemsData);
      let timeline = new Timeline(
        timelineRef.current,
        itemsData,
        groupData,
        options
      );

      timeline.on("itemover", function (params) {
        if (params.item) {
          const item = items.get(params.item);
          if(!item.id.includes("parent") && !item.id.includes("travel"))
            dispatch(setHoveredPolygon(item));
        }
      });
      timeline.on("itemout", function () {
          dispatch(setHoveredPolygon(null));
      });

      // timeline.on("select", (properties) => {
      //   if (properties.items.length > 0) {
      //     const selectedIds = properties.items;
      //     const polygon = lookUp.polygons[selectedIds[0]];
      //     if (polygon?.crewEquipmentId) {
      //       const modifiedPolygon = {
      //         ...polygon,
      //         pathId: selectedIds[0],
      //         equipmentName: getEquipmentName(polygon.crewEquipmentId),
      //         serviceName: getServiceName(polygon.serviceId),
      //       };
      //       dispatch(setSelectedPolygon(modifiedPolygon));
      //     }
      //   } else {
      //     dispatch(setSelectedPolygon(null));
      //   }
      // });

      return () => {
        if (timeline) {
          timeline.off("itemover");
          timeline.off("itemout");
          // timeline.off("select");
          timeline.destroy();
        }
      };
    }
  }, [itemsData, groupData]);

  return (
    <div>
      <div ref={timelineRef} />
    </div>
  );
};

export default ServiceWiseTimelinePlanner;
