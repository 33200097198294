import React, { useEffect, useState } from "react";
import {
  ControlPosition,
  Map,
  useMap,
  useMapsLibrary,
} from "@vis.gl/react-google-maps";
import { useDispatch } from "react-redux";
import { calculateBounds } from "../../../utils/mapZoomCalculation";
import { setIsStreetView } from "../../../slices/globalSlice";
import SummaryBaseLayer from "./SummaryBaseLayer";
import PlannerSelectedLayers from "../../ReadyToPlan/Layers/PlannerSelectedLayers";
import PlanningMapHoveredPolygonLayer from "../../ReadyToPlan/Layers/PlanningMapHoveredPolygonLayer";
import { setClickedPolygonParent } from "../../../slices/planningDashboardSlice";
import PlanningHoveredPolygonPopup from "../../ReadyToPlan/Layers/PlanningHoveredPolygonPopup";

const PlanningDashboardGoogleMaps = ({
  geoJsonData,
  coveredFilteredGeoJson,
}) => {
  const [mapHoveredGeoJson, setMapHoveredgeoJson] = useState(null);
  const [hoveredPolygonData, setHoveredPolygonData] = useState(null);
  const dispatch = useDispatch();

  const map = useMap();
  const mapsLibrary = useMapsLibrary("maps");
  const [viewport, setViewport] = useState({
    center: { lat: 40.7, lng: -74 },
    zoom: 12,
    width: 100,
    height: 200,
    bearing: 0,
    streetViewControl: true,
    fullscreenControl: false,
    fullscreenControlOptions: true,
  });

  // Update the viewport when geoJsonData changes

  useEffect(() => {
    if (
      !geoJsonData ||
      !geoJsonData.features ||
      geoJsonData.features.length === 0
    ) {
      return;
    }

    const bounds = calculateBounds(geoJsonData.features);
    setViewport((prevViewport) => ({
      ...prevViewport,
      center: {
        lat: (bounds[1] + bounds[3]) / 2,
        lng: (bounds[0] + bounds[2]) / 2,
      },
      zoom: 16,
    }));
  }, [geoJsonData]);

  const handleClickedPolygon = (clickedData) => {
    if (clickedData?.object && clickedData?.object?.properties?.pathId) {
      dispatch(setClickedPolygonParent(clickedData?.object?.properties));
    }
  };

  const handleHoveredPolygon = (hoveredData, event) => {
    if (hoveredData?.object && hoveredData?.object?.properties?.pathId) {
      const selectedPolygonId = hoveredData?.object?.properties?.pathId;
      setHoveredPolygonData(hoveredData);

      const filteredFeatures = geoJsonData?.features?.filter((feature) => {
        if (feature?.properties?.pathId) {
          return (
            feature?.properties?.pathId !== undefined &&
            feature?.properties?.pathId === selectedPolygonId
          );
        }
      });
      const filteredGeoJsonData = {
        ...geoJsonData,
        features: filteredFeatures,
      };
      if (filteredGeoJsonData) {
        setMapHoveredgeoJson(filteredGeoJsonData);
      } else {
        setMapHoveredgeoJson(null);
        setHoveredPolygonData(null);
      }
    } else {
      setMapHoveredgeoJson(null);
      setHoveredPolygonData(null);
    }
  };

  useEffect(() => {
    if (mapsLibrary && map) {
      setViewport((prev) => ({
        ...prev,
        mapTypeControlOptions: {
          style: mapsLibrary?.MapTypeControlStyle?.HORIZONTAL_BAR,
          position: 0,
        },
      }));
      const panorama = map?.getStreetView();
      panorama.addListener("visible_changed", () => {
        dispatch(setIsStreetView(panorama.getVisible()));
      });
    }
  }, [mapsLibrary, map]);

  return (
    <div style={{ height: "250px", width: "100%" }}>
      <Map
        {...viewport}
        zoom={viewport?.zoom}
        defaultZoom={viewport?.zoom}
        center={viewport?.center}
        defaultCenter={viewport?.center}
        onCameraChanged={(v) => setViewport(v)}
        gestureHandling={"greedy"}
        draggableCursor="default" // Change this to any cursor style you want when the map is idle
        draggingCursor="move"
        tiltInteractionEnabled={true}
        defaultTilt={0}
        // disableDefaultUI= {true}
        mapId={process.env.REACT_APP_GOOGLE_MAPS_WITH_LABELS_ID}
        defaultBounds={{
          south: 40.5,
          west: -74.2,
          north: 40.9,
          east: -73.8,
        }}
        mapTypeControlOptions={{
          style: mapsLibrary?.MapTypeControlStyle?.HORIZONTAL_BAR,
          position: ControlPosition.TOP_CENTER,
        }}
        stylers={true}
      >
        <SummaryBaseLayer
          layerData={geoJsonData}
          coveredFilteredGeoJson={coveredFilteredGeoJson}
          handleHoveredPolygon={handleHoveredPolygon}
          handleClickedPolygon={handleClickedPolygon}
          //   isSummary={isSummary}
        />
        <PlanningMapHoveredPolygonLayer hoveredGeoJson={mapHoveredGeoJson} />
        <PlanningHoveredPolygonPopup
          hoveredPolygonData={hoveredPolygonData}
          setHoveredPolygonData={setHoveredPolygonData}
        />
        <PlannerSelectedLayers
          layerData={coveredFilteredGeoJson}
          dimLayers={false}
          //   isSummary={isSummary}
        />
      </Map>
    </div>
  );
};

export default PlanningDashboardGoogleMaps;
