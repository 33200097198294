import React from "react";
import { Card, CardContent, Typography, Grid, Box } from "@mui/material";
import { getCustomColor } from "../../utils/theme";
import { IoIosPin } from "react-icons/io";

const CompanyInfo = ({
  companyName,
  address,
  siteName,
  orderStatus,
  description,
}) => {
  const blackShade1 = getCustomColor("blackShade1");

  return (
    <Card sx={{ height: "100%", background: "#edf6f0" }}>
      <CardContent>
        <Typography gutterBottom variant="h4" component="div">
          {companyName}
        </Typography>
        <Typography variant="h6" component="span" color="text.secondary">
          <IoIosPin color="red" fontSize="20px"/> {address}
        </Typography>
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12}>
            <Box marginTop={2}>
              <Typography
                sx={{ color: blackShade1.main }}
                fontWeight="600"
                variant="body1"
                component="span"
                color="text.secondary"
              >
                Site Name:{" "}
              </Typography>
              <Typography
                variant="body1"
                component="span"
                color="text.secondary"
              >
                {siteName}
              </Typography>
            </Box>
            <Box marginTop={2}>
              <Typography
                sx={{ color: blackShade1.main }}
                fontWeight="600"
                variant="body1"
                component="span"
                color="text.secondary"
              >
                Order Status:{" "}
              </Typography>
              <Typography
                variant="body1"
                component="span"
                color="text.secondary"
              >
                {orderStatus === "COMPLETED" ? "Completed" : "In Process"}
              </Typography>
            </Box>
            <Box marginTop={2}>

            <Typography
              sx={{ color: blackShade1.main }}
              fontWeight="600"
              variant="body1"
              component="span"
              color="text.secondary"
            >
              Description:{" "}
            </Typography>
            <Typography variant="body1" component="span" color="text.secondary">
              {description ? description : "N/A"}
            </Typography>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CompanyInfo;
