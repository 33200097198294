import React from "react";
import { InfoWindow } from "@vis.gl/react-google-maps";
import { Typography } from "@mui/material";
import { convertTimestampToFormattedDate } from "../../../utils/common";

const PlanningHoveredPolygonPopup = ({
  hoveredPolygonData,
  setHoveredPolygonData,
  isHoveredPolygonFromTimeline
}) => {
  const data = hoveredPolygonData?.object?.properties;
  if(!data?.pathId) return;
  return (
    <>
      {hoveredPolygonData && (
        <InfoWindow
          position={{
            lat: hoveredPolygonData?.coordinate?.[1],
            lng: hoveredPolygonData?.coordinate?.[0],
          }}
          anchor="left"
          pixelOffset={[0, -20]}
          disableAutoPan={!isHoveredPolygonFromTimeline}
          shouldFocus={false}
          onClose={() => setHoveredPolygonData(null)}
          closeButton={false}
          headerDisabled={true}
        >
          <div className="hoveredRow">
            <Typography component={"span"} variant="body2" fontWeight={600}>
              Area ID :{" "}
            </Typography>
            <Typography component={"span"} variant="body2">
              {data?.pathId}
            </Typography>
          </div>
          <div className="hoveredRow">
            <Typography component={"span"} variant="body2" fontWeight={600}>
              Service :{" "}
            </Typography>
            <Typography component={"span"} variant="body2">
              {data?.fromLookup?.serviceName || data?.layerType}
            </Typography>
          </div>
          {data?.fromLookup && (
            <>
              <div className="hoveredRow">
                <Typography component={"span"} variant="body2" fontWeight={600}>
                  {data?.fromLookup?.measurementType || "Dimensions"} :{" "}
                </Typography>
                <Typography component={"span"} variant="body2">
                  {data?.fromLookup?.measurement ? (`${data?.fromLookup?.measurement} ${data?.fromLookup?.measurementType==="Length" ? "ft" : "sqft"}`) : "N/A"}
                </Typography>
              </div>
              <div className="hoveredRow">
                <Typography component={"span"} variant="body2" fontWeight={600}>
                  Crew Member :{" "}
                </Typography>
                <Typography component={"span"} variant="body2">
                  {data?.fromLookup?.crewName || data?.fromLookup?.crewMemberId || "N/A"}
                </Typography>
              </div>
              <div className="hoveredRow">
                <Typography component={"span"} variant="body2" fontWeight={600}>
                  Equipment :{" "}
                </Typography>
                <Typography component={"span"} variant="body2">
                  {data?.fromLookup?.crewEquipment || data?.fromLookup?.crewEquipmentId || "N/A"}
                </Typography>
              </div>
              <div className="hoveredRow">
                <Typography component={"span"} variant="body2" fontWeight={600}>
                  Start Time :{" "}
                </Typography>
                <Typography component={"span"} variant="body2">
                  {convertTimestampToFormattedDate(data?.fromLookup?.arrivalTime) || "N/A"}
                </Typography>
              </div>
              <div className="hoveredRow">
                <Typography component={"span"} variant="body2" fontWeight={600}>
                  End Time :{" "}
                </Typography>
                <Typography component={"span"} variant="body2">
                  {convertTimestampToFormattedDate(data?.fromLookup?.departureTime) || "N/A"}
                </Typography>
              </div>
            </>
          )}
        </InfoWindow>
      )}
    </>
  );
};

export default PlanningHoveredPolygonPopup;
