import { Box, Checkbox, Drawer, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setPlannedInitialCrewMembers,
  setPlannedLookUpData,
  setServiceWise,
  setShowArrowMarkers,
  setShowConnectingLines,
  setShowLabels,
  setShowNumberCircles,
  setSkipNumber
} from "../../../slices/planningDashboardSlice";
import { IoClose } from "react-icons/io5";
import { AiOutlineControl } from "react-icons/ai";
import { TiArrowSortedDown } from "react-icons/ti";
import LookUpUploadButton from "../../MapScreen/LookUpUploadButton";
import { calculateServicesTotalForPlanning, calculateTotalAssignmentsForCrewMember } from "../../../utils/newPlannerFunctions";
import { findShortestDistance } from "../../../utils/common";
import PlannerUploadButton from "../../MapScreen/PlannerUploadButton";
import { getCustomColor } from "../../../utils/theme";

const PlanningMapControlsDrawer = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const blackShade1 = getCustomColor("blackShade1");
  const serviceWise = useSelector(
    (state) => state.planningDashboard.serviceWise
  );
  const showNumberCircles = useSelector(
    (state) => state.planningDashboard.showNumberCircles
  );
  const skipNumber = useSelector(
    (state) => state.planningDashboard.skipNumber
  );
  const showArrowMarkers = useSelector(
    (state) => state.planningDashboard.showArrowMarkers
  );
  const showConnectingLines = useSelector(
    (state) => state.planningDashboard.showConnectingLines
  );
  const showLabels = useSelector(
    (state) => state.planningDashboard.showLabels
  );
  const dispatch = useDispatch();
  const metaData = JSON.parse(localStorage.getItem("metaData"));

  const [isExpanded, setIsExpanded] = useState(false);
  const toggleMapControlsDrawer = () => {
    setIsExpanded(!isExpanded);
  };

  const handleLookupFileUpload = (file) => {
    if (file) {
      const final = calculateTotalAssignmentsForCrewMember(file);
          const withServices = calculateServicesTotalForPlanning(final);

          dispatch(setPlannedLookUpData(withServices));
          dispatch(setPlannedInitialCrewMembers(withServices.crewMemberWise));
    }
  };

  return (
    <div>
      <button
        onClick={() => setIsExpanded(true)}
        style={{
          background: "#fff",
          borderRadius: "0.25rem",
          border: "solid 1px #dedede",
          padding: "0.5rem 1rem",
          display: "flex",
          gap: "0.5rem",
          alignItems: "center",
          justifyContent: "space-between",
          cursor: "pointer"
        }}
      >
        <span
          style={{
            display: "flex",
            gap: "0.5rem",
            alignItems: "center",
          }}
        >
          <AiOutlineControl />
          <Typography
            variant="body2"
            component="span"
            sx={{
              textTransform: "capitalize",
            }}
          >
            Map Controls
          </Typography>
        </span>
        <TiArrowSortedDown />
      </button>
      <Drawer
        anchor="top"
        open={isExpanded}
        hideBackdrop
        variant="persistent"
        sx={{
          "& .MuiDrawer-paper": {
            borderRadius: "0 0 10px 10px",
            width: 220,
            position: "fixed",
            bottom: 0,
            top: "8vh",
            right: 10,
            left: "auto",
            zIndex: "1",
            maxHeight: "35vh",
          },
        }}
      >
        <div className="drawerWrapper">
          <div className="mainContainer">
            <div className="RightControls">
              <div className="mapControlsTitleContainer">
                <div className="controlsTitle">Map Controls</div>
                <div className="closeButton">
                  <IoClose size={24} onClick={toggleMapControlsDrawer} />
                </div>
              </div>
              <div className="mapFiltersContainer">
                {/* <div className="mapFilters">
                  <label>Labels</label>
                  <div>
                    {!isSolver && (
                      <Checkbox
                        size="small"
                        checked={showLabels}
                        onChange={(e) =>
                          dispatch(setShowLabels(e.target.checked))
                        }
                      />
                    )}
                  </div>
                </div> */}
                <div className="mapFilters">
                  <label>Service Wise</label>
                  <div>
                      <Checkbox
                        size="small"
                        checked={serviceWise}
                        onChange={(e) =>
                          dispatch(setServiceWise(e.target.checked))
                        }
                      />
                  </div>
                </div>
                {metaData && metaData?.maproute_controls && (
                  <>
                    <div className="mapFilters">
                      <label>Show Number Circles</label>
                      <div>
                          <Checkbox
                            size="small"
                            checked={showNumberCircles}
                            onChange={(e) =>
                              dispatch(setShowNumberCircles(e.target.checked))
                            }
                          />
                      </div>
                    </div>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: "5px"
                      }}
                    >
                      <Typography variant="subtitle2" sx={{ color: blackShade1.main }}>skip count</Typography>
                      <Box sx={{display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",}}>
                      <button
                          className="skipButton"
                          
                          onClick={() => {
                            if (skipNumber > 1) {
                              dispatch(setSkipNumber(skipNumber-1))
                            }
                          }}
                        >
                          -
                        </button>
                      <span
                        style={{
                          fontFamily: "Albert Sans",
                          fontSize: "12px",
                          fontWeight: "400",
                          lineHeight: "22.4px",
                          margin: "0 10px",
                          transition: "margin 0.2s ease",
                        }}
                      >
                        {skipNumber}
                      </span>
                        <button
                          className="skipButton"
                          onClick={() => {
                            if (skipNumber < 10) {
                              dispatch(setSkipNumber(skipNumber+1))
                            }
                          }}
                        >
                          +
                        </button>  
                      </Box>

                                           
                    </Box>
                    <div className="mapFilters">
                      <label>Direction Arrows</label>
                      <div>
                          <Checkbox
                            size="small"
                            checked={showArrowMarkers}
                            onChange={(e) =>
                              dispatch(setShowArrowMarkers(e.target.checked))
                            }
                          />
                      </div>
                    </div>
                    <div className="mapFilters">
                      <label>Connecting Lines</label>
                      <div>
                          <Checkbox
                            size="small"
                            checked={showConnectingLines}
                            onChange={(e) =>
                              dispatch(setShowConnectingLines(e.target.checked))
                            }
                          />
                      </div>
                    </div>
                    <div className="mapFilters">
                      <label>Show labels</label>
                      <div>
                          <Checkbox
                            size="small"
                            checked={showLabels}
                            onChange={(e) =>
                              dispatch(setShowLabels(e.target.checked))
                            }
                          />
                      </div>
                    </div>
                    {user?.is_saladmin && (

                    <div className="buttonContainer">
                      <LookUpUploadButton onFileUpload={handleLookupFileUpload} />
                    </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default PlanningMapControlsDrawer;
