import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  InputAdornment,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";

const AddRunPlannerFileNameModal = ({
  isOpen,
  onClose,
  handleStartPlanning,
}) => {
  const [planningMode, setPlanningMode] = useState("DYNAMIC");
  const handlePlanningModeChange = (event) => {
    setPlanningMode(event.target.value);
  };
  const [planningDuration, setPlanningDuration] = useState(5);
  const handlePlanningDurationChange = (event) => {
    // check if value is a number
    if (isNaN(+event.target.value)) {
      return;
    }
    // check if value is above 0
    if (+event.target.value < 0) {
      return;
    }
    setPlanningDuration(+event.target.value);
  };

  const handleClose = () => {
    onClose();
    setPlanningMode("DYNAMIC");
    setPlanningDuration(5);
  };
  const handleStart = () => {
    if (planningMode === "FIXED") {
      if(planningDuration < 5 || planningDuration > 300) {
        toast.warning("Please enter a value between 5 to 300 minutes", {
          autoClose: 1000,
          hideProgressBar: true,
        });
        return;
      } else handleStartPlanning(true, planningDuration*60);
    } else handleStartPlanning(false);
    handleClose();
  };

  return (
    <Dialog
      open={isOpen}
      disableRestoreFocus
      onClose={onClose}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>Start Planning</DialogTitle>
      <DialogContent>
        <Typography variant="body2" component="p" lineHeight={1.25} mb={1}>
          Please configure planning parameters:
        </Typography>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack gap={1}>
            <Stack direction="row" gap={1} alignItems="center">
              <Typography variant="body1" component="p" fontWeight={500}>
                Planning mode:
              </Typography>
              <ToggleButtonGroup
                value={planningMode}
                exclusive
                onChange={handlePlanningModeChange}
                aria-label="planning type"
              >
                <ToggleButton
                  size="xs"
                  value="FIXED"
                  aria-label="fixed runtime"
                >
                  Fixed Runtime
                </ToggleButton>
                <ToggleButton
                  size="xs"
                  value="DYNAMIC"
                  aria-label="dynmaic runtime"
                >
                  Dynamic Runtime
                </ToggleButton>
              </ToggleButtonGroup>
            </Stack>
            <Stack direction="row" gap={1} alignItems="center">
              <Typography variant="body1" component="p" fontWeight={500}>
                Duration:
              </Typography>
              <Input
                value={planningDuration}
                onChange={handlePlanningDurationChange}
                size="small"
                fullWidth
                disabled={planningMode === "DYNAMIC"}
                endAdornment={
                  <InputAdornment position="end">minutes</InputAdornment>
                }
              />
            </Stack>
          </Stack>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleStart} variant="contained">
          Start
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddRunPlannerFileNameModal;
