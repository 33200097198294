import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MainScreen from '../screens/PlannerGoogleMap/MainScreen';
import Header from '../components/Header/Header';
import Login from '../screens/Login/Login';
import "react-toastify/dist/ReactToastify.css";
import { ProtectedRoute } from '../utils/ProtectedRoute';
import { ToastContainer } from 'react-toastify';
import { ROUTES } from '../utils/constants';
import Survey from '../screens/Survey/Survey';
import NotFound from '../screens/NotFound/NotFound';
import Register from '../screens/Register/Register';
import VerifyEmail from '../screens/VerifyEmail/VerifyEmail';
import NewUser from '../screens/NewUser/NewUser';
import ForgotPassword from '../screens/ForgotPassword/ForgotPassword';
import ResetPassword from '../screens/ResetPassword/ResetPassword';
import SummaryScreen from '../screens/SummaryScreen/SummaryScreen';
import NewProperties from '../screens/NewProperties'
import UploadGeoJson from '../screens/UploadGeoJson/UploadGeoJson';
import MaintenancePage from '../screens/MaintenancePage/MaintenancePage';
import MapScreen from '../screens/MapScreen/MapScreen';
import PlanningDashboard from '../screens/ReadyToPlan/PlanningDashboard';

const AppRoutes = () => {
  
  return (
    <>
    <ToastContainer /> 
      <Header />
      <Routes>
        <Route path={ROUTES.LOGIN} element={<Login />} />
        <Route path={ROUTES.REGISTER} element={<Register />} />
        <Route path={ROUTES.VERIFYEMAIL} element={<VerifyEmail />} />
        <Route path={ROUTES.RESETPASSWORD} element={<ForgotPassword />} />
        <Route path={ROUTES.RESETPASSWORDLINK} element={<ResetPassword />} />
        <Route path={ROUTES.NEWUSER} element={<NewUser />} />
        <Route path="*" element={<NotFound />} />
        <Route path={ROUTES.Maintenance} element={<MaintenancePage/>}/>
        <Route element={<ProtectedRoute />}>
          <Route path={ROUTES.SURVEY} element={<Survey />} />
          <Route path={ROUTES.PARCEL} element={<Survey />} />
          <Route path={ROUTES.MAP} element={<MainScreen />} />
          <Route path={ROUTES.HOME} element={<NewProperties/>}/>
          <Route path={ROUTES.SUMMARY} element={<SummaryScreen />} />
          <Route path={ROUTES.UPLOAD} element={<UploadGeoJson />} />
          {/* <Route path={ROUTES.PLANNING} element={<DuringPlanning/>}/> */}
          <Route path={ROUTES.MAPS} element={<MapScreen/>} />
          {/* <Route path={ROUTES.LANDINGPAGE} element={<LandingPage />} /> */}
          <Route path={ROUTES.SERVICES} element={<PlanningDashboard/>}/>
         
        </Route>
      </Routes>
    </>
  );
};

export default AppRoutes;
