import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  Box,
  Typography,
  Stack,
  Button,
  Skeleton,
} from "@mui/material";
import { fetchS3Data } from "../../../Modals/LocationGroupModal/LocationGroupModal";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import { convertSecondsToReadable } from "../../../utils/common";
import {
  calculateServicesTotalForPlanning,
  calculateTotalAssignmentsForCrewMember,
} from "../../../utils/newPlannerFunctions";
import {
  setPlanningStatus,
  setPlanningScore,
  setPlannedInitialCrewMembers,
  setPlannedLookUpData,
} from "../../../slices/planningDashboardSlice";
import {
  useLazyGetPlanningStatusQuery,
  useLazyGetPlanningStatusWithLookupQuery,
  useStartPlanningMutation,
  useStopPlanningMutation,
} from "../../../services/OpenApi";
import { CiPlay1 } from "react-icons/ci";
import { FaArrowLeftLong } from "react-icons/fa6";
import loaderFile from "../loader.gif";
import useViewportWidth from "../../../Hooks/useViewportWidth";
import { isStatusDoneSolving, isStatusMidSolving, isStatusPreSolving, STATUS_MAP } from "./utils";
import StartPlanningModal from "../../../Modals/StartPlanningModal";

const LookupHeader = ({ runPlannerData, isRunPlannerDataLoading = false }) => {
  const navigate = useNavigate();
  const locationQuery = useLocation();
  const searchParams = new URLSearchParams(locationQuery.search);
  const dispatch = useDispatch();
  const width = useViewportWidth();

  const user = JSON.parse(localStorage.getItem("user"));

  const runPlannerServices = Array.isArray(
    runPlannerData?.data?.runPlannerServices
  )
    ? runPlannerData?.data?.runPlannerServices
    : [];
  const runPlannerCrewMembers = Array.isArray(
    runPlannerData?.data?.crewMember
  )
    ? runPlannerData?.data?.crewMember
    : [];
  const lookUp = useSelector(
    (state) => state.planningDashboard.plannedLookUpData,
  );
  const planningStatus = useSelector(
    (state) => state.planningDashboard.planningStatus,
  );
  const crewMembersArray = useMemo(
    () => (lookUp?.crewMembers ? Object.entries(lookUp?.crewMembers) : []),
    [lookUp?.crewMembers],
  );
  const servicesArray = useMemo(
    () => (lookUp?.services ? Object.values(lookUp?.services) : []),
    [lookUp?.services],
  );
  const totalCrewTravelTime = useMemo(
    () =>
      crewMembersArray?.reduce(
        (total, [, crewMember]) => total + crewMember?.totalAssignTravelTime,
        0,
      ),
    [crewMembersArray],
  );
  const totalServiceWorkTime = useMemo(
    () =>
      servicesArray?.reduce((total, service) => total + service?.workTime, 0),
    [servicesArray],
  );
  const totalServiceTravelTime = useMemo(
    () =>
      servicesArray?.reduce((total, service) => total + service?.travelTime, 0),
    [servicesArray],
  );

  // Logic required for stopping planning
  const [stopPlanning, { isLoading: loadingStopPlanning }] =
    useStopPlanningMutation();
  const handleStopPlanning = async () => {
    try {
      await stopPlanning({
        runPlannerId: runPlannerData?.data?.v2runPlannerId,
        orgName: user?.organization,
      }).unwrap();
    } catch (err) {
      console.log(err);
    }
  };

  // logic required for manually updating plan
  const [loadingUpdatePlan, setLoadingUpdatePlan] = useState(false);
  const [getPlanningStatusWithLookup] =
    useLazyGetPlanningStatusWithLookupQuery();
  const handleS3Lookup = async () => {
    try {
      const { runPlannerName, v2runPlannerId } = runPlannerData?.data;
      const data = await fetchS3Data(
        `https://qa-topg-planner-gis.s3.ap-southeast-1.amazonaws.com/LookupResponse/${user?.organization}/${runPlannerName}/LookUp_${v2runPlannerId}`,
      );
      return data;
    } catch (error) {
      console.error("Error fetching API:", error);
      toast.error("Failed to fetch API.");
      return null;
    }
  };
  const handleUpdatePlan = async () => {
    try {
      setLoadingUpdatePlan(true);
      if (isStatusDoneSolving(planningStatus)) {
        const response = await handleS3Lookup();
        if (response) {
          const fileData = response;
          if(!fileData) {
            showToastError("Failed to fetch LookUp data");
            return;
          }
          const final = calculateTotalAssignmentsForCrewMember(fileData);
          const withServices = calculateServicesTotalForPlanning(final);

          dispatch(setPlannedLookUpData(withServices));
          dispatch(setPlannedInitialCrewMembers(withServices.crewMemberWise));
        }
      } else {
        const response = await getPlanningStatusWithLookup({
          runPlannerId: plannerId,
          orgName: user?.organization,
        });
        if (response?.data?.data) {
          const fileData = response?.data?.data?.lookUpResponse;
          if(!fileData) {
            showToastError("Failed to fetch LookUp data");
            return;
          }
          const final = calculateTotalAssignmentsForCrewMember(fileData);
          const withServices = calculateServicesTotalForPlanning(final);

          dispatch(setPlannedLookUpData(withServices));
          dispatch(setPlannedInitialCrewMembers(withServices.crewMemberWise));
        }
      }
    } catch (error) {
      console.error("Error fetching API:", error);
      toast.error("Failed to fetch API.");
    } finally {
      setLoadingUpdatePlan(false);
    }
  };

  const showToastError = (message) => {
    toast.error(
      <Typography color="#1D2220" variant="subtitle2">
        {message}
      </Typography>,
      {
        position: "top-right",
        autoClose: false,
        closeOnClick: true,
      },
    );
  };

  // Logic required for planning & status checking via polling
  const showToast = () => {
    const toastId = toast.info(
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography color="#1D2220" variant="subtitle2">
          You've a new update
        </Typography>
        <Button
          size="sm-md"
          sx={{
            fontWeight: 500,
            color: "#4EA403",
            cursor: "pointer",
          }}
          onClick={handleUpdatePlan}
        >
          Update Plan
        </Button>
      </Stack>,
      {
        position: "top-right",
        autoClose: false,
        closeOnClick: false,
      },
    );

    setTimeout(() => {
      toast.dismiss(toastId);
    }, 8000);
  };
  const localPreviousScoreRef = useRef(null);
  const [getPlanningStatus] = useLazyGetPlanningStatusQuery();
  const intervalIdRef = useRef(null); // Use a ref to store the interval ID to ensure proper cleanup
  const plannerId = searchParams.get("id");
  const pollStatus = async () => {
    try {
      const result = await getPlanningStatus({
        runPlannerId: plannerId,
        orgName: user?.organization,
      });

      const currentStatus = result?.data?.data?.currentStatus;
      const newScore = result?.data?.data?.score;

      // Dispatch the new values
      dispatch(setPlanningStatus(currentStatus));
      dispatch(setPlanningScore(newScore));

      if (
        newScore !== localPreviousScoreRef.current &&
        currentStatus === STATUS_MAP.SOLVING
      ) {
        // update for first time, show toast for next times
        if (!localPreviousScoreRef.current) handleUpdatePlan();
        else showToast();
      }
      if (currentStatus === STATUS_MAP.SOLVING_SCHEDULED) {
        handleUpdatePlan();
      }
      if (currentStatus === STATUS_MAP.EXCEPTION_TERMINATED) {
        showToastError("Solving terminated due to exception.");
      }

      // Update the ref after all checks
      localPreviousScoreRef.current = newScore;

      if (isStatusDoneSolving(currentStatus)) {
        clearInterval(intervalIdRef.current);
        intervalIdRef.current = null;
      }
    } catch (err) {
      console.error("Error in polling:", err);
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }
  };
  const startPolling = () => {
    if (!intervalIdRef.current) {
      intervalIdRef.current = setInterval(pollStatus, 20000);
    }
  };
  const [startPlanning] = useStartPlanningMutation();
  const handleStartPlanning = async (isFixedDuration, algoSecDuration) => {
    try {
      // Start the planning process
      const response = await startPlanning({
        runPlannerId: runPlannerData?.data?.v2runPlannerId,
        orgName: user?.organization,
        isFixedDuration,
        ...(isFixedDuration ? { algoSecDuration } : {}),
      });
      const currentStatus = response?.data?.data?.currentStatus;

      if (currentStatus) {
        dispatch(setPlanningStatus(response?.data?.data?.currentStatus));
        if (isStatusDoneSolving(currentStatus)) {
          toast.success("Planing done successfully", {
            autoClose: 1000,
            hideProgressBar: true,
          });
        }
      }
      if(!response?.error)
        startPolling();
    } catch (err) {
      console.error("Error starting planning:", err);
    }
  };
  // cleanup of interval
  useEffect(() => {
    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
        intervalIdRef.current = null;
      }
    };
  }, []);

  // logic required for handling initialization of status
  // and fetching of lookUp data (s3 or internal) based on status
  useEffect(() => {
    const initializeStatus = async () => {
      try {
        const result = await getPlanningStatus({
          runPlannerId: plannerId,
          orgName: user?.organization,
        });
        const currentStatus = result?.data?.data?.currentStatus;

        dispatch(setPlanningStatus(currentStatus));

        if (isStatusMidSolving(currentStatus)) {
          const response = await getPlanningStatusWithLookup({
            runPlannerId: plannerId,
            orgName: user?.organization,
          });
          if (response?.data?.data) {
            const fileData = response?.data?.data?.lookUpResponse;
            if(!fileData) {
              if(currentStatus!==STATUS_MAP.INITIALIZING)
                showToastError("Failed to fetch LookUp data");
              else startPolling();
              return;
            }
            const final = calculateTotalAssignmentsForCrewMember(fileData);
            const withServices = calculateServicesTotalForPlanning(final);
            dispatch(setPlannedLookUpData(withServices));
            dispatch(setPlannedInitialCrewMembers(withServices.crewMemberWise));
          }

          startPolling();
        } else if (isStatusDoneSolving(currentStatus)) {
          const { runPlannerName, v2runPlannerId } = runPlannerData?.data;
          const response = await fetchS3Data(
            `https://qa-topg-planner-gis.s3.ap-southeast-1.amazonaws.com/LookupResponse/${user?.organization}/${runPlannerName}/LookUp_${v2runPlannerId}`,
          );
          const fileData = response;
          if(!fileData) {
            if(currentStatus!==STATUS_MAP.INITIALIZING)
              showToastError("Failed to fetch LookUp data");
            return;
          }
          const final = calculateTotalAssignmentsForCrewMember(fileData);
          const withServices = calculateServicesTotalForPlanning(final);

          dispatch(setPlannedLookUpData(withServices));
          dispatch(setPlannedInitialCrewMembers(withServices.crewMemberWise));
        }
      } catch (err) {
        console.error("Error initializing status:", err);
      }
    };
    if (runPlannerData?.data) initializeStatus();
  }, [JSON.stringify(runPlannerData?.data)]);

  const [isStartPlanningModalOpen, setIsStartPlanningModalOpen] =
    useState(false);
  const handleStartPlanningInitiator = () => {
    let errorMessage = "";
    if(runPlannerServices.length === 0){
      errorMessage = "Atleast one service is required to start planning";
    } else if (runPlannerCrewMembers.length === 0) {
      errorMessage = "Atleast one crew member is required to start planning";
    }
    if(errorMessage) {
      toast.error(errorMessage, {
        autoClose: 2500,
        hideProgressBar: true,
      });
      return;
    }
    setIsStartPlanningModalOpen(true)
  }

  return (
    <>
      <header
        className="propertyDetailsHeader"
        style={{
          height: "3.2rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 0.5rem",
          gap: "1rem",
        }}
      >
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <SecondaryButton
            sx={{
              color: "#3F8203",
            gap: "0.5rem"
            }}
            size="sm-md"
            onClick={() => navigate(-1)}
          >
            <FaArrowLeftLong /> Back
          </SecondaryButton>
          {isRunPlannerDataLoading ? (
            <Skeleton variant="text" width={300} height={40} />
          ) : (
            <Typography
              component="span"
              fontWeight="600"
              className="propertyTitleValue"
              sx={{ color: "#285203", maxWidth: "30rem" }}
              variant="body1"
            >
              {runPlannerData?.data?.v2PlannerName?.toUpperCase()} -{" "}
              {runPlannerData?.data?.runPlannerName?.toUpperCase()}
            </Typography>
          )}
        </Box>
        <Stack direction="row">
          {planningStatus && !isStatusPreSolving(planningStatus) && (
            <>
              <Stack
                direction="row"
                gap="0.75rem"
                alignItems="center"
                sx={{
                  borderLeft: "2px solid #8DE83D",
                  px: "2rem",
                  color: "#3F8203",
                }}
              >
                <Stack>
                  <Typography variant="body1" component="span" fontWeight={500}>
                    {convertSecondsToReadable(
                      totalServiceTravelTime + totalServiceWorkTime,
                    )}
                  </Typography>
                  <Typography variant="caption" component="span">
                    Total Time
                  </Typography>
                </Stack>
                <img
                  src={loaderFile}
                  alt="loader"
                  style={{
                    width: "3rem",
                    height: "3rem",
                    visibility: loadingUpdatePlan ? "visible" : "hidden",
                  }}
                />
              </Stack>
              <Stack
                direction="row"
                gap="0.75rem"
                alignItems="center"
                sx={{
                  px: "2rem",
                  color: "#3F8203",
                  borderLeft: "2px solid #8DE83D",
                }}
              >
                <Stack>
                  <Typography variant="body1" component="span" fontWeight={500}>
                    {convertSecondsToReadable(totalCrewTravelTime)}
                  </Typography>
                  <Typography variant="caption" component="span">
                    Travel Time
                  </Typography>
                </Stack>
                <img
                  src={loaderFile}
                  alt="loader"
                  style={{
                    width: width > 768 ? "3rem" : "2rem",
                    height: width > 768 ? "3rem" : "2rem",
                    visibility: loadingUpdatePlan ? "visible" : "hidden",
                  }}
                />
              </Stack>
            </>
          )}
        </Stack>
        <Box
          sx={{
            display: "flex",
            gap: "0.5rem",
            alignItems: "center",
            padding: "0 0.5rem",
          }}
        >
          {planningStatus === "NOT_SOLVING" ? (
            <SecondaryButton
              size="sm-md"
              onClick={handleStartPlanningInitiator}
              textcolor="#7F8683"
              sx={{
                curssor: "pointer",
                gap: "0.25rem",
                borderRadius: "0.5rem",
              }}
            >
              Start Planning <CiPlay1 />
            </SecondaryButton>
          ) : planningStatus === STATUS_MAP.INITIALIZING ? (
            <SecondaryButton
              size="sm-md"
              textcolor="#7F8683"
              sx={{
                borderRadius: "0.5rem",
              }}
            >
              Initializing ...
            </SecondaryButton>
          ) : (
            planningStatus === STATUS_MAP.SOLVING && (
              <>
                <Button
                  size="sm-md"
                  variant="contained"
                  color="mintGreen"
                  onClick={handleUpdatePlan}
                  sx={{
                    cursor: "pointer",
                    borderRadius: "0.5rem",
                  width: "6.5rem"
                  }}
                  disabled={loadingUpdatePlan}
                >
                  {loadingUpdatePlan ? "Updating" : "Update Plan"}
                </Button>
                <Button
                  size="sm-md"
                  variant="contained"
                  color="lightMaroon"
                  onClick={handleStopPlanning}
                  sx={{
                    cursor: "pointer",
                    color: "#9B1E4B",
                    borderRadius: "0.5rem",
                  }}
                >
                  {loadingStopPlanning ? "Stopping" : "Stop Planning"}
                </Button>
              </>
            )
          )}
        </Box>
      </header>
      <StartPlanningModal
        isOpen={isStartPlanningModalOpen}
        onClose={() => setIsStartPlanningModalOpen(false)}
        handleStartPlanning={handleStartPlanning}
      />
    </>
  );
};

export default LookupHeader;
