import React, { useEffect, useState } from "react";
import { GeoJsonLayer } from "@deck.gl/layers";
import { DeckGlOverlay } from "../../../utils/deckgl-overlay";
import { getServiceColorRgb, hexToRgb } from "../../../utils/common";

function getDeckGlLayers(
  layerData,
  dimLayers,
  handleHoveredPolygon,
  handleClickedPolygon,
  shouldLowerOpacity
) {
  if (!layerData) return [];

  return [
    new GeoJsonLayer({
      id: "geojson",
      data: layerData,
      stroked: true,
      filled: true,
      getLineWidth: 0.2,
      opacity: (shouldLowerOpacity || dimLayers) ? 0.001 : 1,
      getFillColor: (f) => {
        return f?.properties?.fill
          ? hexToRgb(f?.properties?.fill)
          : getServiceColorRgb(f?.properties?.layerType);
      },
      getLineColor: (f) => {
        return f?.properties?.stroke
          ? hexToRgb(f?.properties?.stroke)
          : getServiceColorRgb(f?.properties?.layerType);
      },
      lineWidthMinPixels: dimLayers ? 0 : 2,
      onHover: (info) => handleHoveredPolygon(info),
      onClick: (info) => handleClickedPolygon(info),
      pickable: true,
    }),
  ];
}

const PlannerBaseLayerWithoutLookup = ({
  layerData,
  coveredFilteredGeoJson,
  handleHoveredPolygon,
  handleClickedPolygon,
  shouldLowerOpacity
}) => {
  const [isVisible, setIsVisible] = useState(true);

  // this visible functionality is added due to webgl context lost issue
  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsVisible(!document.hidden);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);
  const dimLayers = coveredFilteredGeoJson?.features?.length > 0 ? true : false;
  return (
    <DeckGlOverlay
      layers={getDeckGlLayers(
        layerData,
        dimLayers,
        handleHoveredPolygon,
        handleClickedPolygon,
        shouldLowerOpacity
      )}
      pickable={true}
      isVisible={isVisible}
    />
  );
};

export default PlannerBaseLayerWithoutLookup;
