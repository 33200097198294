import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  useResetPasswordConfirmMutation,
} from "../../services/Auth";
import { CircularProgress, IconButton, InputAdornment } from "@mui/material";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { getCustomColor } from "../../utils/theme";
import AuthDesignWrapper from "../../components/AuthDesignWrapper/AuthDesignWrapper";

// TODO remove, this demo shouldn't need to reset the theme.
export default function ResetPassword() {
  const {
    register: registerPassword,
    handleSubmit: handleFormSubmit,
    getValues,
    formState: { errors: errorsPassword },
  } = useForm();
  const url = new URL(window.location.href);
  const paths = url.pathname.split("/");
  const uid = paths[paths.indexOf("uid") + 1];
  const token = paths[paths.indexOf("token") + 1];

  const blackShade1 = getCustomColor("blackShade1");
  const [showPasswordText1, setShowPasswordText1] = useState(false);
  const [showPasswordText2, setShowPasswordText2] = useState(false);

  const handleClickShowPassword1 = () => setShowPasswordText1((show) => !show);
  const handleClickShowPassword2 = () => setShowPasswordText2((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const navigate = useNavigate();
  const [resetPasswordConfirm, { isLoading }] =
    useResetPasswordConfirmMutation();
  const onSubmit = async (data) => {
    const values = {
      uid: uid,
      token: token,
      new_password1: data.password,
      new_password2: data.password,
    };
    try {
      const response = await resetPasswordConfirm(values).unwrap();
      if (response?.data) {
        toast.success("Password reset successfully", {
          autoClose: 1000,
          hideProgressBar: true,
        });
        navigate("/login");
      } else if (response?.error) {
        toast.error(response?.error?.error || response?.error, {
          autoClose: 2000,
          hideProgressBar: true,
        });
      }
    } catch (error) {
      toast.error(error?.data?.token[0], {
        autoClose: 1000,
        hideProgressBar: true,
      });
    }
   
  };

  return (
    <AuthDesignWrapper
      body={
        <>
          <Typography
            component="h3"
            fontWeight="600"
            sx={{ color: blackShade1.main }}
            variant="h5"
          >
            Reset Password
          </Typography>
          <>
            <Box
              component="form"
              noValidate
              onSubmit={handleFormSubmit(onSubmit)}
              sx={{ mt: 1 }}
            >
              <Typography
                fontWeight={"600"}
                sx={{ color: blackShade1.light }}
                component="span"
                variant="body1"
              >
                New Password
              </Typography>
              <TextField
                margin="dense"
                fullWidth
                name="password"
                size="small"
                type={showPasswordText1 ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword1}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPasswordText1 ? (
                          <MdVisibilityOff />
                        ) : (
                          <MdVisibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                  form: {
                    autocomplete: "off",
                  },
                }}
                id="password"
                {...registerPassword("password", {
                  required: "Password is required",
                  pattern: {
                    value:
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                    message:
                      "Password must be at least 8 characters long and contain an uppercase letter, a lowercase letter, a number, and a special character",
                  },
                })}
                error={!!errorsPassword.password}
                helperText={
                  errorsPassword.password ? errorsPassword.password.message : ""
                }
              />
              <Typography
                fontWeight={"600"}
                sx={{ color: blackShade1.light }}
                component="span"
                variant="body1"
              >
                Confirm Password
              </Typography>
              <TextField
                margin="dense"
                fullWidth
                name="confirmPassword"
                size="small"
                type={showPasswordText2 ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword2}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPasswordText2 ? (
                          <MdVisibilityOff />
                        ) : (
                          <MdVisibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                id="confirmPassword"
                {...registerPassword("confirmPassword", {
                  required: "Password is required",
                  validate: (value) =>
                    value === getValues("password") || "Passwords must match",
                })}
                error={!!errorsPassword.confirmPassword}
                helperText={
                  errorsPassword.confirmPassword
                    ? errorsPassword.confirmPassword.message
                    : ""
                }
              />

              <Button
                type="submit"
                fullWidth
                disabled={isLoading}
                variant="contained"
                sx={{ mt: 3, mb: 2, boxShadow: "none" }}
              >
                {isLoading ? <CircularProgress size={24} /> : "Reset Password"}
              </Button>
            </Box>
          </>
        </>
      }
    ></AuthDesignWrapper>
  );
}
