import React from "react";
import { Button, Dialog, DialogContent, Typography } from "@mui/material";
import { IoClose } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { setIsPremium } from "../../slices/globalSlice";

const SelectPlanModal = ({ isOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const handleGetAccess = () => {
    dispatch(setIsPremium(true));
    setIsOpen(false);
  };
  return (
    <Dialog open={isOpen} fullWidth maxWidth="xs">
      <div className="closeButton">
        <IoClose size={24} cursor="pointer" onClick={() => setIsOpen(false)} />
      </div>
      <DialogContent>
        <Typography variant="h5">Hurray! you are eligble for 15 days free access</Typography>
      </DialogContent>
      <Button variant="contained" onClick={handleGetAccess}>
        Get Free Access
      </Button>
    </Dialog>
  );
};

export default SelectPlanModal;
