import React, { useMemo, useRef, useState } from "react";
import AddServiceLayerModal from "../../Modals/AddServiceLayerModal/AddServiceLayerModal";
import { PiWrenchBold } from "react-icons/pi";
import "./PlanningDashboardSidebarSummary.css";
import { Typography, TableCell, styled, tableCellClasses } from "@mui/material";
import { getCustomColor } from "../../utils/theme";
import ServicesTable from "./Components/ServicesTable";
import CrewMemberTable from "./Components/CrewMemberTable";
import { toast } from 'react-toastify'; 
import EquipmentTable from "./Components/EquipmentTable";
import { useSelector } from "react-redux";
import ParkingsTable from "./Components/ParkingsTable";
import { MdKeyboardArrowDown } from "react-icons/md";
import { STATUS_MAP } from "./Components/utils";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e0ffc4",
    color: "#8FE445",
    fontWeight: 600,
    fontSize: "12px",
    height: "10px",
    padding: "0px 6px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "13px",
  },
  whiteSpace: "nowrap",
}));

export const PlanningDashboardSidebarSummary = ({
  runPlannerData,
  isLoading,
}) => {
  const [isAddServiceLayerModalOpen, setIsAddServiceLayerModalOpen] =
    useState(false);
  const blackShade1 = getCustomColor("blackShade1");
  const planningInputData = useSelector(
    (state) => state.planningDashboard.planningInputData
  );

  const servicesGetData = Array.isArray(
    runPlannerData?.data?.runPlannerServices
  )
    ? runPlannerData?.data?.runPlannerServices
    : [];
  const equipmentData = Array.isArray(runPlannerData?.data?.equipmentData)
    ? runPlannerData?.data?.equipmentData
    : [];
  const crewMembersList = Array.isArray(runPlannerData?.data?.crewMember)
    ? runPlannerData?.data?.crewMember
    : [];
  const parkingsList = useMemo(() => 
    Array.isArray(runPlannerData?.data?.parkings)? runPlannerData?.data?.parkings
  : [], [runPlannerData]);
  
  const [isEditServiceModalOpen, setIsEditServiceModalOpen] = useState(false);
  const tempRef = useRef(null);
  const MAX_SERVICES = 10; 
  const handleAddServiceLayer = (e) => {
    const currentServiceCount = servicesGetData.length;
    if (currentServiceCount >= MAX_SERVICES) {
      toast.error("You can only add a maximum of 10 service layers."); 
    } else {
      setIsAddServiceLayerModalOpen(true); 
    }
    e.stopPropagation();
  };

  const planningStatus = useSelector(
    (state) => state.planningDashboard.planningStatus,
  );
  const sortingFunction = (a, b, key)=>{
    let aKey = a?.[key]?.toLowerCase();
    let bKey = b?.[key]?.toLowerCase();
    if(!aKey || !bKey) return 0;
    return aKey.localeCompare(bKey);
  }

  const [expandedSections, setExpandedSections] = useState([
    "services",
    "crewMembers",
    "equipments",
    "parkings",
  ]);
  const toggleSection = (section) => {
    if(expandedSections.includes(section)){
      setExpandedSections(expandedSections.filter(item => item !== section))
    }else{
      setExpandedSections([...expandedSections, section])
    }
  }

  return (
    <div
      className="content"
      id="sidebarDrawer"
      style={{
        position: "relative",
        flex: 1,
      }}
      ref={tempRef}
    >
      <AddServiceLayerModal
        isOpen={isAddServiceLayerModalOpen}
        handleClose={() => setIsAddServiceLayerModalOpen(false)}
        ref={tempRef}
      />

      <div className="ServicesTableSection">
        <div
          className="sectionHeader"
          onClick={() => toggleSection("services")}
          style={{cursor: "pointer"}}
        >
          <div className="sectionHeaderLeft">
            <PiWrenchBold fontSize={26} />
            <Typography
              fontWeight="600"
              display="flex"
              alignItems="center"
              gap={1}
              sx={{ color: blackShade1.shade1, fontSize: "16px" }}
              component="span"
            >
              Services Details
              <MdKeyboardArrowDown size={18} color={blackShade1.shade1}
                style={{
                  transform: expandedSections.includes("services") ? "rotate(180deg)" : "rotate(0deg)",
                  transition: "transform 0.15s ease-in-out",
                }}
              />
            </Typography>
          </div>
          {planningStatus === STATUS_MAP.NOT_SOLVING && (
           <button
           className="addButton"
           onClick={handleAddServiceLayer}
         >
           Add Service Layer
         </button>
          )}
        </div>

        <ServicesTable
          servicesGetData={servicesGetData.toSorted((a,b)=>{
            return sortingFunction(a,b,"coreServiceName")
          })}
          isDataLoading={isLoading}
          isEditServiceModalOpen={isEditServiceModalOpen}
          setIsEditServiceModalOpen={setIsEditServiceModalOpen}
          ref={tempRef}
          isExpanded={expandedSections.includes("services")}
          numExpanded={expandedSections.length}
        />
      </div>
      {/* Crew Member Section */}
      <CrewMemberTable
        crewMembersList={crewMembersList.toSorted((a,b)=>{
          return sortingFunction(a,b,"crewMemberName")
        })}
        isGetApiLoading={isLoading}
        toggleSection={toggleSection}
        isExpanded={expandedSections.includes("crewMembers")}
        numExpanded={expandedSections.length}
      />

      {/* Equipment Section */}
      <EquipmentTable
        equipmentData={equipmentData.toSorted((a,b)=>{
          return sortingFunction(a,b,"name")
        })}
        isGetApiLoading={isLoading}
        toggleSection={toggleSection}
        isExpanded={expandedSections.includes("equipments")}
        numExpanded={expandedSections.length}
      />
      
      <ParkingsTable
        parkingsList={parkingsList.toSorted((a,b)=>{
          return sortingFunction(a,b,"parkingName")
        })}
        isGetApiLoading={isLoading}
        toggleSection={toggleSection}
        isExpanded={expandedSections.includes("parkings")}
        numExpanded={expandedSections.length}
      />

      {/* custom backdrop because MUI backdrop covers the whole page */}
      {(isAddServiceLayerModalOpen || isEditServiceModalOpen) && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(50, 103, 3, 0.75)",
            zIndex: 100,
          }}
        />
      )}
    </div>
  );
};
