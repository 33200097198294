import {
  Box,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { PiWrenchBold } from "react-icons/pi";
import { StyledTableCell } from "../PlanningDashboardSidebarSummary";
import { getCustomColor } from "../../../utils/theme";
import {
  useDeleteCrewMemberMutation,
} from "../../../services/OpenApi";
import AddCrewMemberModal from "../../../Modals/AddCrewMemberModal/AddCrewMemberModal";
import ConfirmationModal from "../../../Modals/ConfirmationModal/ConfirmationModal";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { MdKeyboardArrowDown } from "react-icons/md";
import { APP_HEADER_HEIGHT, LOOKUP_HEADER_HEIGHT, STATUS_MAP } from "./utils";

const CrewMemberTable = ({ crewMembersList, isGetApiLoading,
  toggleSection,
  isExpanded,
  numExpanded,
 }) => {
  const blackShade1 = getCustomColor("blackShade1");
  const greenShade1 = getCustomColor("greenShade1");
  const [crewMembers, setCrewMembers] = useState([]);

  const [isAddEditCrewMemberModalOpen, setIsAddEditCrewMemberModalOpen] =
    useState(false);
  const [deleteCrewMember, { isLoading: isDeleting }] =
    useDeleteCrewMemberMutation();
  const [editingCrewMember, setEditingCrewMember] = useState(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [memberToDelete, setMemberToDelete] = useState(null);
  const user = JSON.parse(localStorage.getItem("user"));
  const planningInputData = useSelector(
    (state) => state.planningDashboard.planningInputData
  );
  const handleAddCrewMember = (newCrewMember) => {
    if (crewMembers.length <=10 ) {
      setCrewMembers([...crewMembers, newCrewMember]);
    } else {
      toast.error("You can only add up to 10 crew members.");
    }
  };  
  React.useEffect(() => {
    if (crewMembersList) {
      setCrewMembers(Array.isArray(crewMembersList) ? crewMembersList : []);
    }
  }, [crewMembersList]);

  const handleEditCrewMember = (member) => {
    setEditingCrewMember(member);
    setIsAddEditCrewMemberModalOpen(true);
  };

  const openDeleteConfirmation = (id) => {
    setMemberToDelete(id);
    setIsConfirmationModalOpen(true);
  };

  const handleDeleteCrewMember = async () => {
    try {
      await deleteCrewMember({
        runPlannerCrewMemberId: memberToDelete,
        organisationName: user?.organization,
      }).unwrap();

      setCrewMembers(
        crewMembers.filter((member) => member.id !== memberToDelete)
      );
      setIsConfirmationModalOpen(false);
      setMemberToDelete(null);
    } catch (error) {
      console.error("Error deleting crew member:", error);
    }
  };

  const planningStatus = useSelector(
    (state) => state.planningDashboard.planningStatus,
  );

  return (
    <div className="crewMemberTableSection">
      <div
        className="sectionHeader"
        style={{cursor: "pointer"}}
        onClick={() => toggleSection("crewMembers")}
      >
        <div className="sectionHeaderLeft">
          <PiWrenchBold fontSize={26} />
          <Typography
            fontWeight="600"
            display="flex"
            alignItems="center"
            gap={1}
            sx={{ color: blackShade1.shade1, fontSize: "16px" }}
            component="span"
            variant="h6"
          >
            Crew Member Details
            <MdKeyboardArrowDown size={18} color={blackShade1.shade1}
              style={{
                transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
                transition: "transform 0.15s ease-in-out",
               }}
            />
          </Typography>
        </div>
        {planningStatus === STATUS_MAP.NOT_SOLVING && (
          <button
            className="addButton"
            onClick={(e) => {
              setIsAddEditCrewMemberModalOpen(true)
              e.stopPropagation();
            }}
          >
            Add Crew Member
          </button>
        )}
      </div>
      {
        isExpanded && (
      <TableContainer
        className="crewTableContainer"
        component={Paper}
        sx={{ boxShadow: "none", height: `calc((100vh - ${APP_HEADER_HEIGHT} - ${LOOKUP_HEADER_HEIGHT} - 184px) / ${numExpanded})`, }}
      >
        <Table stickyHeader size="small" aria-label="sticky table">
          <TableHead sx={{ backgroundColor: "#8FE445", height: "10px" }}>
            <TableRow>
              <StyledTableCell style={{ paddingLeft: "20px" }}>
                Name
              </StyledTableCell>
              {(planningStatus === STATUS_MAP.NOT_SOLVING || isGetApiLoading) && (
                <StyledTableCell
                  sx={{
                    maxWidth: 80,
                    whiteSpace: "nowrap",
                    backgroundColor: "#8FE445",
                    color: "white",
                    fontWeight: 600,
                    fontSize: "16px",
                    padding: "0px 6px",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      paddingRight: "10px",
                    }}
                  >
                    Actions
                  </Box>
                </StyledTableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {isGetApiLoading ? (
              Array.from({ length: 4 }).map((_, index) => (
                <TableRow key={index}>
                  <StyledTableCell style={{ paddingLeft: "20px" }}>
                    <Box sx={{
                      display: "flex",
                      gap: "0.5rem",
                      alignItems: "center",
                    }}>
                      <Skeleton
                        sx={{ bgcolor: greenShade1?.skeleton }}
                        variant="circular"
                        width={18}
                        height={18}
                      />
                      <Skeleton
                        sx={{
                          bgcolor: greenShade1?.skeleton,
                        }}
                        variant="text"
                        width={150}
                        height={25}
                      />
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell align="right"
                    style={{
                      display: "flex",
                    }}
                  >
                    <Skeleton
                      sx={{ bgcolor: greenShade1?.skeleton }}
                      variant="text"
                      width={45}
                      height={25}
                    />
                  </StyledTableCell>
                </TableRow>
              ))
            ) : crewMembers.length === 0 ? (
              <TableRow>
                <StyledTableCell colSpan={2}>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      textAlign: "center",
                      fontSize: "13px",
                      color: "rgb(95, 97, 99)",
                    }}
                  >
                    No crew member added
                  </Typography>
                </StyledTableCell>
              </TableRow>
            ) : (
              crewMembers.map((member) => (
                <TableRow key={member.id}>
                  <StyledTableCell
                    style={{ paddingLeft: "20px" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: "0.5rem",
                        alignItems: "center",
                      }}
                    >
                      <span style={{
                        backgroundColor: member.color,
                        height: "0.8rem",
                        width: "0.8rem",
                        borderRadius: "50%",
                        display: "inline-block",
                      }} />
                      <span style={{
                        maxWidth: 200,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        }}>
                        {member.crewMemberName}
                      </span>
                    </Box>
                  </StyledTableCell>
                  {planningStatus === STATUS_MAP.NOT_SOLVING && (
                    <StyledTableCell
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingRight: "15px",
                      }}
                    >
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <button
                          onClick={() => {
                            handleEditCrewMember(member);
                          }}
                          className="buttonStyle"
                        >
                          <img
                            src="/images/Icons/PencilSimple.png"
                            alt="Edit"
                            style={{ width: "17px", height: "17px" }}
                          />
                        </button>
                        &nbsp;&nbsp;&nbsp;
                        <button
                          onClick={() => openDeleteConfirmation(member.id)}
                          className="buttonStyle"
                        >
                          <img
                            src="/images/Icons/Trash.png"
                            alt="Delete"
                            style={{ width: "17px", height: "17px" }}
                          />
                        </button>
                      </Box>
                    </StyledTableCell>
                  )}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
        )
      }

      <AddCrewMemberModal
        isOpen={isAddEditCrewMemberModalOpen}
        editData={editingCrewMember}
        handleClose={() => {
          setIsAddEditCrewMemberModalOpen(false);
          setEditingCrewMember(null);
        }}
        handleAddCrewMember={handleAddCrewMember}
        setCrewMembers={setCrewMembers}
        setEditingCrewMember={setEditingCrewMember}
      />
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        setIsOpen={setIsConfirmationModalOpen}
        body="Do you want to delete this crew member?"
        handleOk={handleDeleteCrewMember}
        isLoading={isDeleting}
      />
    </div>
  );
};

export default CrewMemberTable;
