import { Button, CircularProgress, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useVerifyEmailMutation } from "../../services/Auth";
import { toast } from "react-toastify";
import AuthDesignWrapper from "../../components/AuthDesignWrapper/AuthDesignWrapper";
import { reset as resetGlobal, setRefreshTokenTimer } from "../../slices/globalSlice";
import { useDispatch } from "react-redux";
import { reset as resetWorkPlan } from "../../slices/workPlanSlice";

const VerifyEmail = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [verifyEmail, { isLoading }] = useVerifyEmailMutation();
  const [isVerificationCompleted, setIsVerificationCompleted] = useState(false);

  const handleOnSubmit = async () => {
    try {
      const response = await verifyEmail({ key: params?.id }).unwrap();
      if (response) {
        setIsVerificationCompleted(true);
      }
    } catch (err) {
      toast.error(err?.data?.detail, {
        autoClose: 1000,
        hideProgressBar: true,
      });
    }
  };
  const handleGettingStarted = () => {
    dispatch(resetGlobal());
    dispatch(resetWorkPlan());
    localStorage.clear();
    navigate("/login");
    dispatch(setRefreshTokenTimer(null));
  };
  return (
    <AuthDesignWrapper
      body={
        <>
          {isVerificationCompleted ? (
            <>
              <Typography fontWeight={"600"} component="h3" variant="h5">
                Verification Complete!
              </Typography>
              <Button
                type="submit"
                fullWidth
                onClick={() => handleGettingStarted()}
                variant="contained"
                sx={{ mt: 4, mb: 2, boxShadow: "none" }}
              >
                Get started
              </Button>
            </>
          ) : (
            <>
              <Typography fontWeight={"600"} component="h3" variant="h5">
                Verify email
              </Typography>
              <Typography
                component="h3"
                textAlign="center"
                marginTop="20px"
                variant="body2"
              >
                Click the below button to Verify Email address
              </Typography>
              <Button
                type="submit"
                fullWidth
                disabled={isLoading}
                onClick={handleOnSubmit}
                variant="contained"
                sx={{ mt: 4, mb: 2, boxShadow: "none" }}
              >
                {isLoading ? <CircularProgress size={24} /> : "Verify Email"}
              </Button>
            </>
          )}
        </>
      }
    ></AuthDesignWrapper>
  );
};

export default VerifyEmail;
