import Pin from '../../../components/Mapbox/Pin';
import { AdvancedMarker, InfoWindow } from '@vis.gl/react-google-maps';
import { Stack, Typography } from '@mui/material';

const PlanningParkingsMarkers = ({
  runPlannerData,
  isPolygonInfoWindowOpen,
  activeParking,
  setActiveParking,
}) => {

  return (
    <div>
      {/* Render parking markers */}
      {runPlannerData?.data?.parkings && runPlannerData.data.parkings.map((parking, index) => {
        return (
          <div style={{ zIndex: 2 }} key={index}
          >
            <AdvancedMarker
              position={{ lat: parking.latitude, lng: parking.longitude }} 
              zIndex={2}
              onMouseEnter={() => setActiveParking(parking)}
              onMouseLeave={() => setActiveParking({})}
            >
              <Pin text="P" color="#ffa600" />
            </AdvancedMarker>
          </div>
        );
      })}
      {/* Render parking popup */}
      {activeParking.id && !isPolygonInfoWindowOpen && (
        <InfoWindow
          position={{
            lat: activeParking?.latitude,
            lng: activeParking?.longitude,
          }}
          anchor="left"
          pixelOffset={[0, -40]}
          disableAutoPan={true}
          shouldFocus={false}
          onClose={() => setActiveParking({})}
          closeButton={false}
          maxWidth={300}
          headerDisabled={true}
          >
          <Stack direction="row" gap={1}>
            <Typography component={"span"} variant="body2" fontWeight={600} minWidth={100}>
              Parking Name :
            </Typography>
            <Typography component={"span"} variant="body2">
              {activeParking?.parkingName}
            </Typography>
          </Stack>
          <Stack direction="row" gap={1}>
            <Typography component={"span"} variant="body2" fontWeight={600} minWidth={100}>
              Parking ID :
            </Typography>
            <Typography component={"span"} variant="body2">
              {activeParking?.id}
            </Typography>
          </Stack>
          <Stack direction="row" gap={1}>
            <Typography component={"span"} variant="body2" fontWeight={600} minWidth={100}>
              Parking Type :
            </Typography>
            <Typography component={"span"} variant="body2">
              {activeParking?.parkingType}
            </Typography>
          </Stack>
          <Stack direction="row" gap={1}>
            <Typography component={"span"} variant="body2" fontWeight={600} minWidth={100}>
              Address :
            </Typography>
            <Typography component={"span"} variant="body2">
              {activeParking?.address}
            </Typography>
          </Stack>
        </InfoWindow>
      )}
    </div>
  );
};

export default PlanningParkingsMarkers;
