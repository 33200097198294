import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { config } from "./config";
import { APIENDPOINTS, ROUTES } from "./constants";
import {
  reset as resetGlobal,
  setIsLogin,
  setToken,
} from "../slices/globalSlice";
import { reset as resetWorkPlan } from "../slices/workPlanSlice";

// Existing base queries
const rtkBaseQuery = fetchBaseQuery({
  baseUrl: config.BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().global.token;
    if (token) {
      headers.set("Authorization", `JWT ${token}`);
    }
    return headers;
  },
});

const openBaseQuery = fetchBaseQuery({
  baseUrl: config.BASE_URL_OPEN,
});

const testingBaseQuery = async (args, api, extraOptions) => {
  const baseQuery = fetchBaseQuery({
    baseUrl: config.BASE_URL_TESTING,
  });
  let result = await baseQuery(args, api, extraOptions);
  if (
    (result.error && result.error.status === 502) ||
    (result.error && result.error.originalStatus === 502)
  ) {
    console.error(
      "502 Bad Gateway detected. Redirecting to maintenance page...",
    );
    api.dispatch(navigateToMaintenance());
    return {
      error: {
        status: 502,
        data: "The server is temporarily unavailable. Please try again later.",
      },
    };
  }

  return result;
};
const navigateToMaintenance = () => (dispatch) => {
  window.location.href = ROUTES.Maintenance;
};

// Base query with reauthorization logic
const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await rtkBaseQuery(args, api, extraOptions);
  if (result.error) {
    if (result.error.status === 401 || result.error.originalStatus === 401) {
      const state = api.getState();
      let token = state.global.token;

      // Fallback to localStorage if token is missing in the Redux state
      if (!token) {
        token = localStorage.getItem("accessToken");
      }

      try {
        const { data } = await rtkBaseQuery(
          {
            url: APIENDPOINTS.REFRESHTOKEN,
            method: "POST",
            body: { token: token },
          },
          api,
          extraOptions,
        );
        if (data?.token) {
          // Update token and retry the original request
          api.dispatch(setToken(data.token));
          localStorage.setItem("accessToken", data.token);
          result = await rtkBaseQuery(args, api, extraOptions);
        } else {
          api.dispatch(resetGlobal());
          api.dispatch(resetWorkPlan());
          api.dispatch(setIsLogin(false));
          localStorage.clear();
        }
      } catch (err) {
        console.error("Token refresh failed", err);
        api.dispatch(resetGlobal());
        api.dispatch(resetWorkPlan());
        api.dispatch(setIsLogin(false));
        localStorage.clear();
      }
    } else if (
      result.error.status === 502 ||
      result.error.originalStatus === 502
    ) {
      window.location.href = ROUTES.Maintenance;
      return result;
    }
  }

  return result;
};

// Export your enhanced base query
export { baseQueryWithReauth, openBaseQuery, testingBaseQuery };
