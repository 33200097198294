import { useCallback, useEffect, useRef, useState } from "react";
import {
  isCircle,
  isMarker,
  isPolygon,
  isPolyline,
} from "./types";
import {
  initializeFromGeoJSON,
  setOverlay,
  updateOverlays,
} from "../../slices/drawingSlice";
import { useSelector } from "react-redux";
import { calculatePolygonArea } from "../../utils/common";

export function useDrawingManagerEvents(
  drawingManager,
  overlaysShouldUpdateRef,
  dispatch,
  parcelGeoJSON,
  readOnly
) {
  const [modalOpen, setModalOpen] = useState(false);
  const [pendingPolygon, setPendingPolygon] = useState(null);
  const parcelData = useSelector((state) => state.drawing.parcelData);
  const isInitialized = useRef(false);
  const parcelDataRef = useRef(parcelData);

  useEffect(() => {
    parcelDataRef.current = parcelData;
  }, [parcelData]);

  const checkPolygonOverlap = useCallback((newPolygon) => {
    const newPath = newPolygon.getPath();
    const existingPolygons = parcelDataRef.current;
    for (const overlay of existingPolygons) {
      const existingPath = overlay.geometry.getPath();
      for (let i = 0; i < newPath.getLength(); i++) {
        if (
          window.google.maps.geometry.poly.containsLocation(
            newPath.getAt(i),
            overlay.geometry
          )
        ) {
          return true;
        }
      }
      for (let i = 0; i < existingPath.getLength(); i++) {
        if (
          window.google.maps.geometry.poly.containsLocation(
            existingPath.getAt(i),
            newPolygon
          )
        ) {
          return true;
        }
      }
    }
    return false;
  }, []);

  const handleConfirm = () => {
    setModalOpen(false);
    if (pendingPolygon) {
      // Add the polygon to the map and continue the process

      dispatch(
        setOverlay({
          type: window.google.maps.drawing.OverlayType.POLYGON,
          overlay: pendingPolygon,
        })
      );
      setPendingPolygon(null);
    }
  };

  const handleClose = () => {
    setModalOpen(false);
    if (pendingPolygon) {
      // Remove the polygon from the map
      pendingPolygon.setMap(null);
      setPendingPolygon(null);
    }
  };

  useEffect(() => {
    if (!drawingManager) return;

    const eventListeners = [];

    const addUpdateListener = (eventName, drawResult) => {
      const updateListener = window.google.maps.event.addListener(
        drawResult.overlay,
        eventName,
        () => {
          if (eventName === "dragstart") {
            overlaysShouldUpdateRef.current = false;
          }

          if (eventName === "dragend") {
            overlaysShouldUpdateRef.current = true;
          }

          if (overlaysShouldUpdateRef.current) {
            dispatch(updateOverlays());
          }
        }
      );

      eventListeners.push(updateListener);
    };

    const calculatePolygonSquareFeetArea = (polygon) => {
        const path = polygon.getPath();
        const area = window?.google?.maps?.geometry?.spherical?.computeArea(path);
        return (area * 10.7639).toFixed(2);
    }

    const addPolygonEditListeners = (polygon) => {
      const path = polygon.getPath();

      const editListeners = [
        window.google.maps.event.addListener(path, "set_at", () =>
        calculatePolygonArea(polygon)
        ),
        window.google.maps.event.addListener(path, "insert_at", () =>
        calculatePolygonArea(polygon)
        ),
        window.google.maps.event.addListener(path, "remove_at", () =>
        calculatePolygonArea(polygon)
        ),
      ];

      eventListeners.push(...editListeners);
    };

    if (parcelGeoJSON && drawingManager) {
      dispatch(
        initializeFromGeoJSON({
          parcelGeoJSON,
          drawingManager,
          addUpdateListener,
          readOnly,
        })
      );
      isInitialized.current = true;
    }

    if (!readOnly) {
      const overlayCompleteListener = window.google.maps.event.addListener(
        drawingManager,
        "overlaycomplete",
        (drawResult) => {
          switch (drawResult.type) {
            case window.google.maps.drawing.OverlayType.MARKER:
              ["dragend"].forEach((eventName) =>
                addUpdateListener(eventName, drawResult)
              );
              break;
            case window.google.maps.drawing.OverlayType.POLYGON:
              const newPolygon = drawResult.overlay;
              const area = calculatePolygonSquareFeetArea(newPolygon);
              if (area < 1) {
                newPolygon.setMap(null);
                setPendingPolygon(null);
                return;
              }
              if (checkPolygonOverlap(newPolygon)) {
                setPendingPolygon(newPolygon);
                setModalOpen(true);
                return;
              }
              ["mouseup"].forEach((eventName) =>
                addUpdateListener(eventName, drawResult)
              );
              addPolygonEditListeners(newPolygon);
              break;
          }

          dispatch(setOverlay(drawResult));
        }
      );
      eventListeners.push(overlayCompleteListener);
    }

    return () => {
      eventListeners.forEach((listener) =>
        window.google.maps.event.removeListener(listener)
      );
    };
  }, [
    dispatch,
    drawingManager,
    overlaysShouldUpdateRef,
    parcelGeoJSON,
    readOnly,
    checkPolygonOverlap,
  ]);
  return {
    modalOpen,
    setModalOpen,
    handleConfirm,
    handleClose,
    pendingPolygon,
  };
}

export function useOverlaySnapshots(map, parcelData, overlaysShouldUpdateRef) {
  useEffect(() => {
    if (!map || !parcelData) return;
    for (const overlay of parcelData) {
      overlaysShouldUpdateRef.current = false;
      overlay.geometry.setMap(map);

      const { radius, center, position, path } = overlay.snapshot;

      if (isCircle(overlay.geometry)) {
        overlay.geometry.setRadius(radius ?? 0);
        overlay.geometry.setCenter(center ?? null);
      } else if (isMarker(overlay.geometry)) {
        overlay.geometry.setPosition(position);
      } else if (isPolygon(overlay.geometry) || isPolyline(overlay.geometry)) {
        overlay.geometry.setPath(path ?? []);
      }

      overlaysShouldUpdateRef.current = true;
    }

    return () => {
      for (const overlay of parcelData) {
        overlay.geometry.setMap(null);
      }
    };
  }, [map, overlaysShouldUpdateRef, parcelData]);
}
