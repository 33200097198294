import {
  Box,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { PiWrenchBold } from "react-icons/pi";
import { StyledTableCell } from "../PlanningDashboardSidebarSummary";
import { getCustomColor } from "../../../utils/theme";
import { useDeleteRunPlannerParkingMutation } from "../../../services/OpenApi";
import ConfirmationModal from "../../../Modals/ConfirmationModal/ConfirmationModal";
import { useDispatch, useSelector } from "react-redux";
import { setAddParkingMode } from "../../../slices/planningDashboardSlice";
import { useState } from "react";
import { toast } from "react-toastify";
import { MdKeyboardArrowDown } from "react-icons/md";
import { APP_HEADER_HEIGHT, LOOKUP_HEADER_HEIGHT, STATUS_MAP } from "./utils";

const ParkingsTable = ({ parkingsList, isGetApiLoading,
  toggleSection,
  isExpanded,
  numExpanded,
 }) => {
  const blackShade1 = getCustomColor("blackShade1");
  const greenShade1 = getCustomColor("greenShade1");
  const dispatch = useDispatch();
  const addParkingMode = useSelector(
    (state) => state.planningDashboard.addParkingMode,
  );
  const user = JSON.parse(localStorage.getItem("user"));

  const handleToggleAddParkingMode = (e) => {
    dispatch(setAddParkingMode(!addParkingMode));
    e.stopPropagation();
  };

  const [deleteRunPlannerParking, { isLoading: isDeleting }] =
    useDeleteRunPlannerParkingMutation();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [parkingToDelete, setParkingToDelete] = useState(null);
  const openDeleteConfirmation = (id) => {
    setIsConfirmationModalOpen(true);
    setParkingToDelete(id);
  };
  const handleDeleteParking = async () => {
    if(parkingsList.length === 1) {
      toast.warn("At least one parking location is required.");
      return;
    }
    try {
      await deleteRunPlannerParking({
        runPlannerParkingId: parkingToDelete,
        orgName: user?.organization,
      });
      setIsConfirmationModalOpen(false);
      setParkingToDelete(null);
    } catch (error) {
      console.error("Error deleting parking:", error);
      toast.error("Something went wrong, please try again.");
    }
  };

  const planningStatus = useSelector(
    (state) => state.planningDashboard.planningStatus,
  );

  return (
    <div className="crewMemberTableSection">
      <div
        className="sectionHeader"
        style={{cursor: "pointer"}}
        onClick={() => toggleSection("parkings")}
      >
        <div className="sectionHeaderLeft">
          <PiWrenchBold fontSize={26} />
          <Typography
            fontWeight="600"
            display="flex"
            alignItems="center"
            gap={1}
            sx={{ color: blackShade1.shade1, fontSize: "16px" }}
            component="span"
            variant="h6"
          >
            Parkings
            <MdKeyboardArrowDown size={18} color={blackShade1.shade1}
              style={{
                transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
                transition: "transform 0.15s ease-in-out",
               }}
            />
          </Typography>
        </div>
        {planningStatus === STATUS_MAP.NOT_SOLVING && (
          <button className={addParkingMode? "addButtonCancel": "addButton"} onClick={handleToggleAddParkingMode}>
            {addParkingMode? "Cancel Adding Parking" : "Add New Parking"}
          </button>
        )}
      </div>
      {
        isExpanded && (
      <TableContainer
        className="crewTableContainer"
        component={Paper}
        sx={{ boxShadow: "none", height: `calc((100vh - ${APP_HEADER_HEIGHT} - ${LOOKUP_HEADER_HEIGHT} - 184px) / ${numExpanded})`, }}
      >
        <Table stickyHeader size="small" aria-label="sticky table">
          <TableHead sx={{ backgroundColor: "#8FE445", height: "10px" }}>
            <TableRow>
              <StyledTableCell style={{ paddingLeft: "20px" }}>
                Name
              </StyledTableCell>
              <StyledTableCell style={{ paddingLeft: "20px" }}>
                Type
              </StyledTableCell>
              {planningStatus === STATUS_MAP.NOT_SOLVING && (
                <StyledTableCell
                  sx={{
                    maxWidth: 80,
                    whiteSpace: "nowrap",
                    backgroundColor: "#8FE445",
                    color: "white",
                    fontWeight: 600,
                    fontSize: "16px",
                    padding: "0px 6px",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      paddingRight: "10px",
                    }}
                  >
                    Actions
                  </Box>
                </StyledTableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {isGetApiLoading ? (
              Array.from({ length: 4 }).map((_, index) => (
                <TableRow key={index}>
                  <StyledTableCell style={{ paddingLeft: "20px" }}>
                    <Skeleton
                      sx={{
                        bgcolor: greenShade1?.skeleton,
                      }}
                      variant="text"
                      width={150}
                      height={25}
                    />
                  </StyledTableCell>
                  <StyledTableCell style={{ paddingLeft: "20px" }}>
                    <Skeleton
                      sx={{
                        bgcolor: greenShade1?.skeleton,
                      }}
                      variant="text"
                      width={150}
                      height={25}
                    />
                  </StyledTableCell>
                </TableRow>
              ))
            ) : parkingsList.length === 0 ? (
              <TableRow>
                <StyledTableCell colSpan={2}>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      textAlign: "center",
                      fontSize: "13px",
                      color: "rgb(95, 97, 99)",
                    }}
                  >
                    No parking added
                  </Typography>
                </StyledTableCell>
              </TableRow>
            ) : (
              parkingsList.map((parking) => (
                <TableRow key={parking.id}>
                  <StyledTableCell style={{ paddingLeft: "20px" }}>
                    {parking.parkingName}
                  </StyledTableCell>
                  <StyledTableCell style={{ paddingLeft: "20px" }}>
                    {parking.parkingType}
                  </StyledTableCell>
                  {planningStatus === STATUS_MAP.NOT_SOLVING && (
                    <StyledTableCell
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingRight: "15px",
                      }}
                    >
                      <button
                        onClick={() => openDeleteConfirmation(parking.id)}
                        className="buttonStyle"
                      >
                        <img
                          src="/images/Icons/Trash.png"
                          alt="Delete"
                          style={{ width: "17px", height: "17px" }}
                        />
                      </button>
                    </StyledTableCell>
                  )}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
        )
      }
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        setIsOpen={setIsConfirmationModalOpen}
        body="Do you want to delete this parking?"
        handleOk={handleDeleteParking}
        isLoading={isDeleting}
      />
    </div>
  );
};

export default ParkingsTable;
