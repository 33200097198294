import React, { useEffect, useRef, useState } from "react";
import { Timeline } from "vis-timeline/standalone";
import { DataSet } from "vis-data";
import "vis/dist/vis.min.css";
import { addHours, endOfDay, startOfDay } from "date-fns";
import {
  convertStartAndEndTimes,
  convertTimestampToFormattedDate,
} from "../../../utils/common";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedPolygon } from "../../../slices/globalSlice";
import { setHoveredPolygon } from "../../../slices/planningDashboardSlice"

const CrewMemberTimelinePlanner = () => {
  const timelineRef = useRef();
  const dispatch = useDispatch();
  const lookUp = useSelector(
    (state) => state.planningDashboard.plannedLookUpData
  );
  const selectedDay = useSelector((state) => state.global.selectedDay);
  const [groupData, setGroupData] = useState([]);
  const [itemsData, setItemsData] = useState([]);
  const [options, setOptions] = useState({
    stack: false,
    // height: "100%",
    maxHeight: "32vh",
    zoomMin: 100000, // Adjust as necessary
    // zoomMax: 50000000,
    // showMajorLabels: false,
    orientation: { axis: "top", item: "top" },
    showTooltips: true,
    preferZoom: true,
    verticalScroll: true,
    selectable: true,
    groupHeightMode: "fitItems",
    autoResize: true,
    fontSize: 10,
    xss: {
      disabled: true
    },
    tooltip: {
      overflowMethod: "cap"
    }
  });

  const getCrewMemberName = (crewMemberId) => {
    const crewData = lookUp?.crewMembers;
    if (crewData[crewMemberId]) {
      return crewData[crewMemberId].name;
    } else {
      return "Crew member not found";
    }
  };
  const getServiceName = (serviceId) => {
    const servicesData = lookUp?.services;
    if (servicesData[serviceId]) {
      return servicesData[serviceId].serviceName;
    } else {
      return "Service not found";
    }
  };
  const getEquipmentName = (crewEquipmentId) => {
    const equipments = lookUp?.equipments;
    if (equipments[crewEquipmentId]) {
      return equipments[crewEquipmentId].type;
    } else {
      return "Equipment not found";
    }
  };

  const transformDataForTimeline = (lookup) => {
    const crewMemberWise = lookup.crewMemberWise;
    const timelineData = [];

    for (const crewId in crewMemberWise) {
      const crewData = crewMemberWise[crewId];
      let activeService = {};
      let task = {}
      let prevTask = {}
      crewData?.polygons.forEach((polygonId, index) => {
        const polygon = {...lookup.polygons[polygonId]};
        const crewMemberName = getCrewMemberName(polygon?.crewMemberId);
        const serviceName = getServiceName(polygon?.serviceId).replace(
          /\s/g,
          "_"
        );
        const equipmentName = getEquipmentName(polygon?.crewEquipmentId);
        const color = lookup?.services[polygon?.serviceId]?.color;
        task = {
          id: polygonId,
          content: `<span style="font-size:12px">${polygonId.split("-").slice(1, 3).join("-")}</span>`,
          start: convertTimestampToFormattedDate(polygon?.arrivalTime),
          end: convertTimestampToFormattedDate(polygon?.departureTime),
          group: `${crewMemberName} ${getServiceName(polygon?.serviceId)} ${polygon?.serviceId}`,
          title: `<div>
            <div class="individualContainer">
            <div>Area ID : ${polygonId.split("-").slice(1,3).join("-")}</div>
            <div>Crew Member : ${crewMemberName}</div>
            <div>Service Name : ${serviceName.replace("_", " ")}</div>
            <div>Equipment Name : ${equipmentName.replace("_", " ")}</div>
            <div>Start Time : ${convertTimestampToFormattedDate(
              polygon?.arrivalTime
            )}</div>
            <div>End Time : ${convertTimestampToFormattedDate(
              polygon?.departureTime
            )}</div>
            <div>${polygon?.measurementType || "Dimensions"} : ${polygon.measurement ? (`${polygon.measurement} ${polygon?.measurementType==="Length" ? "ft" : "sqft"}`) : "N/A"}</div>
            </div>
          </div>`,
          style: `border-color: ${color}; background-color: ${color}; color:white;`,
          extraProps: {
            arrivalTime: polygon?.arrivalTime,
            departureTime: polygon?.departureTime,
          }
          // className: `${serviceName}`, // You can define custom CSS classes here
        };

        // handle summary
        if(!activeService.id) {
          // if no activeService then initialise
          activeService = {
            ...task,
            id: "parent_"+task.id,
            group: polygon?.crewMemberId?.toString(),
            content: "",
            // for iteration control
            serviceId: polygon?.serviceId,
          };
        } else if(activeService?.serviceId === polygon?.serviceId) {
          // new service same as current active service
          activeService = {
            ...activeService,
            end: task.end,
          };
        } else {
          // new service different from older service
          // if polygon is from parking, add travel bar
          if(polygon?.fromParking && prevTask?.id) {
            // check if time between departure of last polygon and arrival of current polygon is greater than 0
            // if so add travel bar for arrival: departure of last & departure: arrival of current
            if(task?.extraProps?.arrivalTime - prevTask?.extraProps?.departureTime > 0) {
              timelineData.push({
                id: "travel"+polygonId,
                content: "",
                start: convertTimestampToFormattedDate(prevTask?.extraProps?.departureTime),
                end: convertTimestampToFormattedDate(task?.extraProps?.arrivalTime),
                group: polygon?.crewMemberId?.toString(),
                title: `<div>
                  <div class="individualContainer">
                  <div>Crew Member : ${crewMemberName}</div>
                  <div>Start Time : ${convertTimestampToFormattedDate(
                    prevTask?.extraProps?.departureTime
                  )}</div>
                  <div>End Time : ${convertTimestampToFormattedDate(
                    task?.extraProps?.arrivalTime
                  )}</div>
                  </div>
                </div>`,
                style: `border-color: #7a7a7a; background-color: #7a7a7a; color:white;`
              });
            }
          }
          let finalTitle = activeService?.title?.split("End Time : ")[0];
          finalTitle += `End Time : ${task.start}`;
          finalTitle += "</div></div></div>";
          finalTitle = `<div><div class="individualContainer"><div>Crew Member :`+finalTitle.split("<div>Crew Member :")[1];
          timelineData.push({
            ...activeService,
            title: finalTitle
          });
          activeService = {
            ...task,
            id: "parent_"+task.id,
            content: "",
            group: polygon?.crewMemberId?.toString(),
            // for iteration control
            serviceId: polygon?.serviceId,
          };
        }

        timelineData.push(task);
        prevTask = task
      });
      // append any trailing activeService
      if(activeService?.id && activeService.end === task.end) {
        let finalTitle = activeService?.title?.split("End Time : ")[0];
        finalTitle += `End Time : ${activeService.end}`;
        finalTitle += "</div></div></div>";
        finalTitle = `<div><div class="individualContainer"><div>Crew Member :`+finalTitle.split("<div>Crew Member :")[1];
        timelineData.push({
          ...activeService,
          title: finalTitle,
        });
      }
    }
    return timelineData;
  };

  const transformGroupDataForTimeline = (lookup) => {
    const crewMembers = lookup.crewMembers;
    const groupData = [];
    const crewMemberWise = lookup.crewMemberWise;

    for (const crewId in crewMemberWise) {
      const crewData = crewMemberWise[crewId];
      crewData?.polygons.forEach((polygonId) => {
        const polygon = lookup.polygons[polygonId];
        const crewMemberName = getCrewMemberName(polygon?.crewMemberId);
        const serviceName = getServiceName(polygon?.serviceId).replace(
          /_/g,
          " "
        );
        const uniqueId = `${crewMemberName} ${serviceName} ${polygon?.serviceId}`;

        const group = {
          id: uniqueId,
          crewName: crewMemberName,
          content: `${serviceName}`,
        };
        const groupExists = groupData.some(
          (existingGroup) => existingGroup.id === uniqueId
        );

        if (!groupExists) {
          groupData.push(group);
        }
      });
    }

    for (const crewMemberId in crewMembers) {
      const crewMemberData = crewMembers[crewMemberId];
      const nestedGroups = [];
      const crewMemberColor= lookup?.crewMembers?.[crewMemberId]?.color

      const group = {
        id: crewMemberId,
        content: `<div style="display:flex;align-items:center;gap:0.5rem">
          ${crewMemberData?.name}
          <span style="background-color:${crewMemberColor};height:0.8rem;width:0.8rem;border-radius:50%">
          </span>
        </div>`,
        showNested: false,
        value: parseInt(crewMemberId),
        crewName: crewMemberData?.name
        // className: 'timeline-event', // You can define custom CSS classes here
      };
      groupData.forEach((item) => {
        if (item.id.includes(crewMemberData?.name)) {
          nestedGroups.push(item.id);
        }
      });

      const containsName = groupData.some((item) =>
        item.id.includes(crewMemberData?.name)
      );

      if (containsName) {
        group.nestedGroups = nestedGroups;
      }
      groupData.push(group);
    }
    return groupData.sort((a, b) => {
      if(a.crewName && b.crewName) {
        return a.crewName.localeCompare(b.crewName);
      }
      return b.value - a.value;
    });
  };

  const findMinMaxTimeFromLookup = (lookup) => {
    let minStartTime = null;
    let maxEndTime = null;
    const crewMemberWise = lookup.crewMemberWise;
  
    for (const crewId in crewMemberWise) {
      const crewData = crewMemberWise[crewId];
      crewData?.polygons.forEach((polygonId) => {
        const polygon = lookup.polygons[polygonId];
  
        // Ensure arrivalTime and departureTime are valid numbers before processing
        if (polygon?.arrivalTime && !isNaN(polygon.arrivalTime)) {
          const startDate = convertTimestampToFormattedDate(polygon.arrivalTime);
  
          if ((!minStartTime || startDate < minStartTime) && startDate) {
            minStartTime = startDate;
          }
        }
  
        if (polygon?.departureTime && !isNaN(polygon.departureTime)) {
          const endDate = convertTimestampToFormattedDate(polygon.departureTime);
  
          if ((!maxEndTime || endDate > maxEndTime) && endDate) {
            maxEndTime = endDate;
          }
        }
      });
    }
  
    return { minStartTime, maxEndTime };
  };

  useEffect(() => {
    if (lookUp) {
      let lookupData = { ...lookUp };
      const timelineData = transformDataForTimeline(lookupData);
      setItemsData(timelineData);
      const groupData = transformGroupDataForTimeline(lookupData);
      setGroupData(groupData);
      const bounds = findMinMaxTimeFromLookup(lookupData);
      const chartStart = new Date(bounds.minStartTime);
      const chartEnd = new Date(bounds.maxEndTime);
      const adjustedTimes = convertStartAndEndTimes(chartStart, chartEnd);
      const clusterOpts = {
        start: adjustedTimes.start,
        end: addHours(adjustedTimes.end, 1),
        min: startOfDay(adjustedTimes.start),
        max: endOfDay(adjustedTimes.end),
      };
      setOptions((prevOptions) => ({
        ...prevOptions,
        ...clusterOpts,
      }));
    }
  }, [lookUp, selectedDay]);

  useEffect(() => {
    if (itemsData && groupData) {
      const items = new DataSet(itemsData);
      let timeline = new Timeline(
        timelineRef.current,
        itemsData,
        groupData,
        options
      );

      timeline.on("itemover", function (params) {
        if (params.item) {
          const item = items.get(params.item);
          if(!item.id.includes("parent") && !item.id.includes("travel"))
            dispatch(setHoveredPolygon(item));
        }
      });
      timeline.on("itemout", function () {
          dispatch(setHoveredPolygon(null));
      });

      timeline.on("select", (properties) => {
        if (properties.items.length > 0) {
          const selectedIds = properties.items;
          const polygon = lookUp.polygons[selectedIds[0]];
          if (polygon?.crewEquipmentId) {
            const modifiedPolygon = {
              ...polygon,
              pathId: selectedIds[0],
              equipmentName: getEquipmentName(polygon.crewEquipmentId),
              serviceName: getServiceName(polygon.serviceId),
            };
            dispatch(setSelectedPolygon(modifiedPolygon));
          }
        } else {
          dispatch(setSelectedPolygon(null));
        }
      });

      return () => {
        if (timeline) {
          timeline.off("itemover");
          timeline.off("itemout");
          timeline.off("select");
          timeline.destroy();
        }
      };
    }
  }, [itemsData, groupData]);

  return (
    <div>
      <div ref={timelineRef} />
    </div>
  );
};

export default CrewMemberTimelinePlanner;
