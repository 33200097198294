import { Button } from '@mui/material';
import React, { useRef } from 'react';
import { toast } from "react-toastify";

const PlannerUploadButton = ({ onFileUpload }) => {
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    if (onFileUpload && file) {
      try {
        const fileContents = await readFileContents(file);
        const jsonData = JSON.parse(fileContents);

        // Pass the jsonData to the parent component or perform other actions
        onFileUpload(jsonData);
      } catch (error) {
        console.error('Error reading or parsing file:', error);
        toast.error("Not able to parse the uploaded file", {
          autoClose: 2000,
          hideProgressBar: true,
        });
      }
    }
    if (event.target.files.length > 0) {
      event.target.value = null;
    }
  };

  const readFileContents = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        resolve(event.target.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsText(file);
    });
  };

  return (
    <div className="uploadButtonContainer">
      <input
        type="file"
        accept=".geojson, .json"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
      <Button fullWidth size='small' variant='contained' onClick={handleButtonClick}>Upload Shortest Loop</Button>
    </div>
  );
};

export default PlannerUploadButton;
