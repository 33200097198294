import React, { useEffect, useState } from "react";
import { DeckGlOverlay } from "../../../utils/deckgl-overlay";
import { PathLayer } from "@deck.gl/layers";
import { getServiceColorRgb } from "../../../utils/common";
import { useSelector } from "react-redux";
import useViewportWidth from "../../../Hooks/useViewportWidth";

function getDeckGlLayers(linesData, showShortestLoop) {
  return [
    new PathLayer({
      id: "dashed-path-layer-prev",
      data: linesData,
      getPath: (d) => [d.source, d.target], // Adjust according to your data structure
      getColor: (d) => d.color,
      getWidth: 0.2,
      getDashArray: [3, 2],
      dashJustified: true,
      dashGapPickable: true,
      widthMinPixels: 3,
      visible: showShortestLoop ? true : false,
      pickable: false
    }),
  ];
}

const ShortestLoopLines = () => {
  const [shortestLoopLines, setShortestLoopLines] = useState([]);
  const [isVisible, setIsVisible] = useState(true);
  const width = useViewportWidth();
  const shortestLoopData = useSelector(
    (state) => state.global.shortestLoopData
  );
  const showShortestLoop = useSelector(
    (state) => state.global.showShortestLoop
  );

  const selectedServices = useSelector(
    (state) => state.global.selectedServices
  );

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsVisible(!document.hidden);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (shortestLoopData) {
      const linesData = [];
      const filteredLoopData = shortestLoopData?.filter(
        (loop) => selectedServices[loop?.service]
      );
      filteredLoopData?.forEach((route) => {
        const entryPoint = route?.currPoint;
        const exitPoint = route?.nextPoint;

        const transformedObject = {
          source: [entryPoint?.lon, entryPoint?.lat],
          target: [exitPoint?.lon, exitPoint?.lat],
          color: getServiceColorRgb(route?.service),
        };

        linesData.push(transformedObject);
      });

      setShortestLoopLines(linesData);
    } else {
      setShortestLoopLines([]);
    }
  }, [shortestLoopData, selectedServices]);
  return (
    <DeckGlOverlay
      layers={getDeckGlLayers(shortestLoopLines, showShortestLoop)}
      typedArrayManagerProps={
        width < 800 ? { overAlloc: 1, poolSize: 0 } : null
      }
      isVisible={isVisible}
    />
  );
};

export default ShortestLoopLines;
