import {
  ControlPosition,
  Map,
  useMapsLibrary,
} from "@vis.gl/react-google-maps";
import React, { useEffect, useState } from "react";
import BaseUploadedLayers from "./BaseUploadedLayers";
import HoveredGeoJsonLayers from "./HoveredGeoJsonLayers";
import { calculateBounds } from "../../utils/mapZoomCalculation";
import ConnectingLines from "./ConnectingLines";
import BaseUploadedGeoBufLayers from "./BaseUploadedGeoBufLayers";
import ShortestLoopLines from "./ShortestLoopLines";

const UploadGeoJsonMap = ({
  geoJsonData,
  layerTypes,
  connectingLinesData,
  crewMembers,
  bufValue,
  shortestLoopSelection,
  shortestLoopData
}) => {
  const [hoveredData, setHoveredData] = useState(null);
  const [hoveredGeoJson, setHoveredGeoJson] = useState(null);
  const [hoverConnectingLines, setHoverConnectingLines] = useState(null);
  const mapsLibrary = useMapsLibrary("maps");
  const [viewport, setViewport] = useState({
    center: { lat: 40.7, lng: -74 },
    zoom: 12,
    width: 800,
    height: 400,
    bearing: 0,
    streetViewControl: true,
    fullscreenControl: false,
    fullscreenControlOptions: true,
  });

  useEffect(() => {
    const connectingLines = [];
    if (hoveredData && hoveredData?.properties?.touches) {
      const filteredFeatures = geoJsonData.features.filter((feature) =>
        hoveredData.properties.touches.includes(feature.properties.pathId)
      );
      setHoveredGeoJson(filteredFeatures);
      if(filteredFeatures) {
        filteredFeatures?.map((feature) => {
          if(hoveredData?.properties?.center && feature.properties?.center) {
            connectingLines.push({
              source: [
                hoveredData?.properties?.center[0],
                hoveredData?.properties?.center[1],
              ],
              target: [
                feature.properties?.center[0],
                feature.properties?.center[1],
              ],
            });
          }
        });
        setHoverConnectingLines(connectingLines)
      }
    } else {
      setHoveredGeoJson(null);
      setHoverConnectingLines(null)
    }
  }, [hoveredData]);

  useEffect(() => {
    if (
      !geoJsonData ||
      !geoJsonData.features ||
      geoJsonData.features.length === 0
    ) {
      return;
    }

    const bounds = calculateBounds(geoJsonData.features);
    setViewport((prevViewport) => ({
      ...prevViewport,
      center: {
        lat: (bounds[1] + bounds[3]) / 2,
        lng: (bounds[0] + bounds[2]) / 2,
      },

      zoom: 16.5,
    }));
  }, [geoJsonData]);
  return (
    <div style={{ height: "92vh", width: "100%" }}>
      <Map
        {...viewport}
        zoom={viewport?.zoom}
        defaultZoom={viewport?.zoom}
        center={viewport?.center}
        defaultCenter={viewport?.center}
        onCameraChanged={(v) => setViewport(v)}
        gestureHandling={"greedy"}
        draggableCursor="default" // Change this to any cursor style you want when the map is idle
        draggingCursor="move"
        tiltInteractionEnabled={true}
        defaultTilt={0}
        mapId={process.env.REACT_APP_GOOGLE_MAPS_WITHOUT_LABELS_ID}
        defaultBounds={{
          south: 40.5,
          west: -74.2,
          north: 40.9,
          east: -73.8,
        }}
        mapTypeControlOptions={{
          style: mapsLibrary?.MapTypeControlStyle?.HORIZONTAL_BAR,
          position: ControlPosition.TOP_CENTER,
        }}
      >
        { !bufValue && ( <BaseUploadedLayers
          geoJson={geoJsonData}
          layerTypes={layerTypes}
          hoveredGeoJson={hoveredGeoJson}
          setHoveredData={setHoveredData}
        />
        )}
        { bufValue && (<BaseUploadedGeoBufLayers
          geoJson={geoJsonData}
          layerTypes={layerTypes}
        />
        ) }
        <HoveredGeoJsonLayers
          geoJson={hoveredGeoJson}
          layerTypes={layerTypes}
        />
        <ConnectingLines
          connectingLinesData={connectingLinesData}
          crewMembers={crewMembers}
          forHover={false}
        />
        <ConnectingLines
          connectingLinesData={hoverConnectingLines}
          forHover={true}
        />
        <ShortestLoopLines
          shortestLoopSelection={shortestLoopSelection}
          shortestLoopData={shortestLoopData}
          geoJsonData={geoJsonData}
        />
      </Map>
    </div>
  );
};

export default UploadGeoJsonMap;
