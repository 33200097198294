import React, { useEffect, useState } from "react";
import { GeoJsonLayer } from "@deck.gl/layers";
import { DeckGlOverlay } from "../../utils/deckgl-overlay";
import useViewportWidth from "../../Hooks/useViewportWidth";
function getDeckGlLayers(finalData) {
  if (!finalData) return [];

  return [
    new GeoJsonLayer({
      id: "geojsonBase",
      data: finalData,
      stroked: true,
      filled: true,
      getLineWidth: 0.2,
    //   getFillColor: (f) => {
    //     return f?.properties?.fill ?  hexToRgb(f?.properties?.fill) : getServiceColorRgb(f?.properties?.layerType);
    //   },
    //   getLineColor: (f) => {
    //     return f?.properties?.stroke ?  hexToRgb(f?.properties?.stroke) : getServiceColorRgb(f?.properties?.layerType)
    //   },
      lineWidthMinPixels: 2,
      parameters: {
        depthTest: false,
      },
    }),
  ];
}

const ShortestLoopLines = ({ shortestLoopSelection, shortestLoopData, geoJsonData }) => {
  const [finalData, setFinalData] = useState([]);
  const [isVisible, setIsVisible] = useState(true);
  const width = useViewportWidth();

  // this visible functionality is added due to webgl context lost issue
  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsVisible(!document.hidden);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

useEffect(() => {
    if (!shortestLoopData || !geoJsonData?.features || !shortestLoopSelection) return;

    // Create a map of pathId to features for faster lookups
    const pathIdMap = geoJsonData.features.reduce((acc, feature) => {
        const pathId = feature?.properties?.pathId;
        if (pathId) acc[pathId] = feature;
        return acc;
    }, {});

    const filteredData = {
        type: "FeatureCollection",
        features: Object.keys(shortestLoopData)
            .filter(shortestLoopKey => shortestLoopSelection[shortestLoopKey]) // Exclude keys with `false` in `shortestLoopSelection`
            .map(shortestLoopKey => {
                const feature = {
                    geometry: {
                        type: "LineString",
                        coordinates: []
                    },
                    properties: {
                        color: "" // Dynamically assign a color here if needed
                    },
                    type: "Feature"
                };

                shortestLoopData[shortestLoopKey]?.forEach(polygonId => {
                    const filteredPolygonId = polygonId.split("-").slice(1, 3).join("-");
                    const matchedFeature = pathIdMap[filteredPolygonId];

                    if (matchedFeature?.properties?.center) {
                        feature.geometry.coordinates.push(matchedFeature.properties.center);
                    }
                });

                return feature;
            })
    };

    setFinalData(filteredData); // Debug or use filteredData as needed
}, [shortestLoopData, shortestLoopSelection, geoJsonData]);


  return (
    <DeckGlOverlay
      layers={getDeckGlLayers(finalData)}
      typedArrayManagerProps={
        width < 800 ? { overAlloc: 1, poolSize: 0 } : null
      }
      isVisible={isVisible}
    />
  );
};

export default ShortestLoopLines;
