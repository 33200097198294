import * as turf from "@turf/turf";
import axios from "axios";

/**
 * Calculate the distance between two points in miles.
 * @param {Array<number>} point1 - The first point [longitude, latitude].
 * @param {Array<number>} point2 - The second point [longitude, latitude].
 * @returns {number} - The distance between the two points in miles.
 */
export const calculateDistanceBetweenPoints = (point1, point2) => {
    const from = turf.point(point1);
    const to = turf.point(point2);
    const options = { units: "miles" };
    const distance = turf.distance(from, to, options);
    return distance;
};

export const fetchAddressFromLatLong = async (coordinates) => {
    try {
        const { data } = await axios.get(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${coordinates.lng},${coordinates.lat}.json?access_token=${process.env.REACT_APP_MAPBOX_KEY}`
        );
        if (data.features.length > 0)
            return (data.features[0].place_name);
        else return ""
    } catch (error) {
        console.log("Error fetching address:", error);
    }
};

export function smoothPanTo(map, lat, lng, finalZoom = 15) {
    if (!lat || !lng) return;
    const currBounds = map.getBounds();
    const currZoom = map.getZoom();
    if (currBounds.contains({ lat, lng })) {
        map.panTo({ lng, lat });
        if (currZoom < finalZoom) {
            const additionor = currZoom < 8 ? 1.5 : currZoom < 15 ? 1.25 : 1;
            const newZoom = currZoom + additionor;
            map.setZoom(newZoom);
            setTimeout(() => {
                smoothPanTo(lat, lng);
            }, 200);
        }
    } else if (currZoom > 1) {
        const subtractor = currZoom > 15 ? 1 : currZoom > 8 ? 1.25 : 1.5;
        const newZoom = currZoom - subtractor;
        map.setZoom(newZoom);
        setTimeout(() => {
            smoothPanTo(lat, lng);
        }, 200);
    }
}